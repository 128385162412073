// eslint-disable-next-line import/no-extraneous-dependencies
import segmentPlugin from "@analytics/segment";
import { type TrackingAction, type UserID } from "@technis/shared";
import Analytics, { type AnalyticsInstance } from "analytics";

const projectId = process.env.SEGMENT_PROJECT;

export namespace SegmentTracking {
  let segment: AnalyticsInstance;
  export const init = async (): Promise<void> => {
    if (projectId) {
      // eslint-disable-next-line new-cap
      segment = Analytics({
        app: "Dashboard Vision",
        plugins: [
          segmentPlugin({
            writeKey: projectId,
          }),
        ],
      });
    }
  };

  export const saveAction = <T extends Omit<TrackingAction, "id" | "projectId" | "receivedAt" | "sessionId" | "category">>(
    payload: T,
  ): void => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { name, ...rest } = payload;
    if (projectId && Boolean(segment)) {
      segment.track(payload.name, rest);
    }
  };

  export const setUser = <T extends object>(userId: UserID, traits?: T): void => {
    if (projectId && Boolean(segment)) {
      segment.identify(userId, traits);
    }
  };

  export const trackViewActivity = (type: string, name: string, url: string): void => {
    if (projectId && Boolean(segment)) {
      segment.page({ type, name, url });
    }
  };
}
