import { type TrackingAction, type TrackingView, type UserID } from "@technis/shared";
import { Tracker } from "@technis/tracking";
import platform from "platform";

// const { RETAIL } = TrackingActionCategory;

const projectId = process.env.TRACKING_PROJECT_ID || "technis-retail";
const isDevelopment = process.env.ENV !== "production";

export namespace TechnisTracking {
  let trackerObject: Tracker;

  /**
   * construtor to initialize the socket connection
   */
  export const startConnection = async (): Promise<void> => {
    try {
      const { platform: osPlatform, userAgent } = window.navigator;
      const { name: browser, os, version: browserVersion } = platform.parse(userAgent);
      trackerObject = new Tracker({
        os: osPlatform,
        osVersion: os?.version || "DEFAULT VERSION",
        browser,
        browserVersion,
        projectId,
      });
      await trackerObject.initWSConnection();
    } catch (error) {
      if (isDevelopment) {
        console.error(error);
      }
    }
  };

  /**
   * service method to check tracker object exist or not
   */
  export const isServiceObjectInitialized = (errorMessage: string): boolean | undefined => {
    try {
      if (trackerObject && trackerObject.socket) return true;

      // noinspection ExceptionCaughtLocallyJS
      throw new Error("Tracker object has not been initialized");
    } catch (error) {
      if (isDevelopment) {
        console.error(error, errorMessage);
      }
    }
  };

  /**
   * set the user id to session
   * @param {UserID} userId
   */
  export const setUser = (userId: UserID): void => {
    if (isServiceObjectInitialized("Something went wrong in updating user")) trackerObject.setUser({ userId });
  };

  // /**
  //  * set the language to session
  //  * @param { language: UserLanguage } payload
  //  */
  // export const setLanguage = (payload: { language: UserLanguage }): void => {
  //   if (isServiceObjectInitialized("Something went wrong in updating language")) trackerObject.setLanguage(payload);
  // };

  /**
   * save action
   * @param {TrackingAction} payload
   */
  export const saveAction = (payload: Omit<TrackingAction, "id" | "projectId" | "receivedAt" | "sessionId">): void => {
    if (isServiceObjectInitialized("Something went wrong in save action")) trackerObject.saveAction(payload);
  };

  /**
   * save View Activity
   * @param {object} payload
   */
  export const trackViewActivity = (payload: Pick<TrackingView, "topic" | "name">): void => {
    if (isServiceObjectInitialized("Something went wrong in save view activity")) trackerObject.saveViewActivity(payload);
  };

  /**
   * Close the websocket communication
   */
  export const endConnection = (): void => {
    if (isServiceObjectInitialized("Something went wrong in ending connection")) trackerObject.endConnection();
  };

  // export const ActivityMessages = TrackingActionMessages[RETAIL];
}
