import { type TrackingAction, type UserID } from "@technis/shared";
import ReactGA from "react-ga4";

const projectId = process.env.GOOGLE_ANALYTICS_PROJECT;

export namespace GATracking {
  export const init = async (): Promise<void> => {
    if (projectId) {
      ReactGA.initialize(projectId);
    }
  };

  export const saveAction = (payload: Omit<TrackingAction, "id" | "projectId" | "receivedAt" | "sessionId">): void => {
    ReactGA.event({
      category: payload.category,
      action: payload.type || "",
      label: payload.name,
    });
  };

  export const setUser = (userId: UserID, traits: Record<string, unknown>): void => {
    ReactGA.set({ userId, traits });
  };

  /**
   * save View Activity
   * @param {string} path
   */
  export const trackViewActivity = (path: string): void => {
    ReactGA.send({ hitType: "pageview", page: path });
  };
}
