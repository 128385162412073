/* eslint-disable max-lines */

import {
  Combustibles,
  ConditionType,
  ElectricitySources,
  Genders,
  KpiRetailGroupType,
  MetricAggregations,
  RetailMetrics,
  UserLanguage,
  WaterTemperatures,
  Weekdays,
} from "@technis/shared";
import { Month } from "@technis/ui";

import { OnboardingStepKey } from "@common/enums/onboarding";

export const translation = {
  errors: {
    notFound: "errors.notFound",
    noServer: "errors.noServer",
    tokenExpired: "errors.tokenExpired",
    unHandled: "errors.unHandled",
    invalidPhone: "errors.invalidPhone",
    emptyEmail: "errors.emptyEmail",
    invalidEmail: "errors.invalidEmail",
    emptyFirstName: "errors.emptyFirstName",
    emptyLastName: "errors.emptyLastName",
  },
  aggregationErrors: {
    timeRangeTooWide: {
      text: "aggregationErrors.timeRangeTooWide.text",
      subText: "aggregationErrors.timeRangeTooWide.subText",
    },
  },
  otp: {
    invalidCode: "otp.invalidCode",
  },
  language: {
    [UserLanguage.FR]: "language.fr",
    [UserLanguage.EN]: "language.en",
    [UserLanguage.IT]: "language.it",
    [UserLanguage.AR]: "language.ar",
    [UserLanguage.DE]: "language.de",
    [UserLanguage.ES]: "language.es",
  },
  toast: {
    error: "toast.error",
    success: "toast.success",
    dataExportSuccess: "toast.dataExportSuccess",
    dataExportError: "toast.dataExportError",
    createAlertSuccess: "toast.createAlertSuccess",
    updateAlertSuccess: "toast.updateAlertSuccess",
    deleteAlertSuccess: "toast.deleteAlertSuccess",
    deleteAlertError: "toast.deleteAlertError",
  },
  actions: {
    save: "actions.save",
    cancel: "actions.cancel",
    createNewAlert: "actions.createNewAlert",
    delete: "actions.delete",
    discard: "actions.discard",
    quit: "actions.quit",
  },
  validation: {
    passwordLengthError: "validation.passwordLengthError",
    passwordPolicyError: "validation.passwordPolicyError",
    passwordExtendedPolicyError: "validation.passwordExtendedPolicyError",
    requiredField: "validation.requiredField",
    passwordsNotEqual: "validation.passwordsNotEqual",
    emailRequired: "validation.emailRequired",
    emailInvalid: "validation.emailInvalid",
    smsCodeInvalid: "validation.smsCodeInvalid",
    mustBeGreaterThan: "validation.mustBeGreaterThan",
  },
  distributionChartLabels: {
    inside: "distributionChartLabels.inside",
    insideUnderThreshold: "distributionChartLabels.insideUnderThreshold",
  },
  common: {
    descriptors: {
      [KpiRetailGroupType.ELEC_ORIGIN]: {
        [ElectricitySources.ALL]: "common.descriptors.ELEC_ORIGIN.ALL",
        [ElectricitySources.GRID]: "common.descriptors.ELEC_ORIGIN.GRID",
        [ElectricitySources.SELF]: "common.descriptors.ELEC_ORIGIN.SELF",
      },
      [KpiRetailGroupType.ELEC_DESTINATION]: {
        [ElectricitySources.ALL]: "common.descriptors.ELEC_DESTINATION.ALL",
        [ElectricitySources.GRID]: "common.descriptors.ELEC_DESTINATION.GRID",
        [ElectricitySources.SELF]: "common.descriptors.ELEC_DESTINATION.SELF",
      },
      [KpiRetailGroupType.COMBUSTIBLE_TYPE]: {
        [Combustibles.ALL]: "common.descriptors.COMBUSTIBLE_TYPE.ALL",
        [Combustibles.OIL]: "common.descriptors.COMBUSTIBLE_TYPE.OIL",
        [Combustibles.GAS]: "common.descriptors.COMBUSTIBLE_TYPE.GAS",
        [Combustibles.COAL]: "common.descriptors.COMBUSTIBLE_TYPE.COAL",
        [Combustibles.WOOD]: "common.descriptors.COMBUSTIBLE_TYPE.WOOD",
      },
      [KpiRetailGroupType.WATER_TEMPERATURE]: {
        [WaterTemperatures.ALL]: "common.descriptors.WATER_TEMPERATURE.ALL",
        [WaterTemperatures.COLD]: "common.descriptors.WATER_TEMPERATURE.COLD",
        [WaterTemperatures.HOT]: "common.descriptors.WATER_TEMPERATURE.HOT",
      },
      [KpiRetailGroupType.ELEC_USE]: {
        ALL: "common.descriptors.ELEC_USE.ALL",
        COMMON: "common.descriptors.ELEC_USE.COMMON",
        HEATING: "common.descriptors.ELEC_USE.HEATING",
        TENANTS: "common.descriptors.ELEC_USE.TENANTS",
      },
      [KpiRetailGroupType.WATER_USE]: {
        ALL: "common.descriptors.WATER_USE.ALL",
      },
      [KpiRetailGroupType.COMBUSTIBLE_USE]: {
        ALL: "common.descriptors.COMBUSTIBLE_USE.ALL",
      },
      [KpiRetailGroupType.GENDER]: {
        [Genders.ALL]: "common.descriptors.GENDER.ALL",
        [Genders.MALE]: "common.descriptors.GENDER.MALE",
        [Genders.FEMALE]: "common.descriptors.GENDER.FEMALE",
        [Genders.UNKNOWN]: "common.descriptors.GENDER.UNKNOWN",
      },
      [KpiRetailGroupType.ALL]: {
        ALL: "common.descriptors.ALL.ALL",
      },
    },
    metrics: {
      [RetailMetrics.DWELL]: "common.metrics.dwell",
      [RetailMetrics.CAPTURE_RATE]: "common.metrics.captureRate",
      [RetailMetrics.POPULARITY]: "common.metrics.popularity",
      [RetailMetrics.AFFLUENCE_IN]: "common.metrics.affluenceIn",
      [RetailMetrics.AFFLUENCE_OUT]: "common.metrics.affluenceOut",
      [RetailMetrics.INSIDE]: "common.metrics.inside",
      [RetailMetrics.GROUP_SIZE_IN]: "common.metrics.groupSizeIn",
      [RetailMetrics.GROUP_SIZE_OUT]: "common.metrics.groupSizeOut",
      [RetailMetrics.N_GROUPS_IN]: "common.metrics.nGroupIn",
      [RetailMetrics.N_GROUPS_OUT]: "common.metrics.nGroupOut",
      [RetailMetrics.OCCUPANCY]: "common.metrics.occupancy",
      [RetailMetrics.AFFLUENCE_IN_QUEUE]: "common.metrics.affluenceInQueue",
      [RetailMetrics.AFFLUENCE_OUT_QUEUE]: "common.metrics.affluenceOutQueue",
      [RetailMetrics.INSIDE_QUEUE]: "common.metrics.insideQueue",
      [RetailMetrics.DWELL_QUEUE]: "common.metrics.dwellQueue",
      [RetailMetrics.ABANDON_RATE_QUEUE]: "common.metrics.abandonRateQueue",
      [RetailMetrics.TEMPERATURE]: "common.metrics.temperature",
      [RetailMetrics.HUMIDITY_LEVEL]: "common.metrics.humidity",
      [RetailMetrics.CO2_LEVEL]: "common.metrics.co2",
      [RetailMetrics.GROUP_PERCENTAGE]: "common.metrics.groupPercentage",
      [RetailMetrics.OCCUPANCY_STATUS]: "common.metrics.occupancyStatus",
      [RetailMetrics.DEVICE_STATUS]: "common.metrics.deviceStatus",
      [RetailMetrics.DENSITY]: "common.metrics.density",
      [RetailMetrics.REVERSE_DENSITY]: "common.metrics.reverseDensity",
      [RetailMetrics.WAITING_TIME]: "common.metrics.waitingTime",
      ENTRANCES: "common.metrics.entrances",
      PRODUCT: "common.metrics.product",
      [RetailMetrics.LIMIT]: "common.kpiMetrics.limit",
      [RetailMetrics.BINARY_OCCUPANCY]: "common.metrics.binaryOccupancy",
      [RetailMetrics.CO2_LEVEL_SCORE]: "common.metrics.co2LevelScore",
      [RetailMetrics.COMBUSTIBLE_CONSUMPTION]: "common.metrics.combustibleConsumption",
      [RetailMetrics.COMBUSTIBLE_CONSUMPTION_CO2_EQ]: "common.metrics.combustibleConsumptionCo2Eq",
      [RetailMetrics.COMBUSTIBLE_CONSUMPTION_CO2_EQ_PER_SQM]: "common.metrics.combustibleConsumptionCo2EqPerSqm",
      [RetailMetrics.COMBUSTIBLE_CONSUMPTION_ENERGY_EQ]: "common.metrics.combustibleConsumptionEnergyEq",
      [RetailMetrics.COMBUSTIBLE_CONSUMPTION_ENERGY_EQ_PER_SQM]: "common.metrics.combustibleConsumptionEnergyEqPerSqm",
      [RetailMetrics.COMBUSTIBLE_CONSUMPTION_PER_SQM]: "common.metrics.combustibleConsumptionPerSqm",
      [RetailMetrics.COMFORT_SCORE]: "common.metrics.comfortScore",
      [RetailMetrics.ELECTRICITY_CONSUMPTION]: "common.metrics.electricityConsumption",
      [RetailMetrics.ELECTRICITY_CONSUMPTION_CO2_EQ]: "common.metrics.electricityConsumptionCo2Eq",
      [RetailMetrics.ELECTRICITY_CONSUMPTION_CO2_EQ_PER_SQM]: "common.metrics.electricityConsumptionCo2EqPerSqm",
      [RetailMetrics.ELECTRICITY_CONSUMPTION_ENERGY_EQ]: "common.metrics.electricityConsumptionEnergyEq",
      [RetailMetrics.ELECTRICITY_CONSUMPTION_ENERGY_EQ_PER_SQM]: "common.metrics.electricityConsumptionEnergyEqPerSqm",
      [RetailMetrics.ELECTRICITY_CONSUMPTION_PER_SQM]: "common.metrics.electricityConsumptionPerSqm",
      [RetailMetrics.ELECTRICITY_PRODUCTION]: "common.metrics.electricityProduction",
      [RetailMetrics.ELECTRICITY_PRODUCTION_CO2_EQ]: "common.metrics.electricityProductionCo2Eq",
      [RetailMetrics.ELECTRICITY_PRODUCTION_CO2_EQ_PER_SQM]: "common.metrics.electricityProductionCo2EqPerSqm",
      [RetailMetrics.ELECTRICITY_PRODUCTION_ENERGY_EQ]: "common.metrics.electricityProductionEnergyEq",
      [RetailMetrics.ELECTRICITY_CONSUMPTION_ON_DAY]: "common.metrics.electricityConsumptionOnDay",
      [RetailMetrics.ELECTRICITY_CONSUMPTION_ON_INTERVAL]: "common.metrics.electricityConsumptionOnInterval",
      [RetailMetrics.ELECTRICITY_CONSUMPTION_CO2_EQ_ON_DAY]: "common.metrics.electricityConsumptionCo2EqOnDay",
      [RetailMetrics.ELECTRICITY_CONSUMPTION_CO2_EQ_ON_INTERVAL]: "common.metrics.electricityConsumptionCo2EqOnInterval",
      [RetailMetrics.ELECTRICITY_CONSUMPTION_ENERGY_EQ_ON_DAY]: "common.metrics.electricityConsumptionEnergyEqOnDay",
      [RetailMetrics.ELECTRICITY_CONSUMPTION_ENERGY_EQ_ON_INTERVAL]: "common.metrics.electricityConsumptionEnergyEqOnInterval",
      [RetailMetrics.ELECTRICITY_PRODUCTION_ON_DAY]: "common.metrics.electricityProductionOnDay",
      [RetailMetrics.ELECTRICITY_PRODUCTION_ON_INTERVAL]: "common.metrics.electricityProductionOnDay",
      [RetailMetrics.ELECTRICITY_PRODUCTION_CO2_EQ_ON_DAY]: "common.metrics.electricityProductionCo2EqOnDay",
      [RetailMetrics.ELECTRICITY_PRODUCTION_CO2_EQ_ON_INTERVAL]: "common.metrics.electricityProductionCo2EqOnInterval",
      [RetailMetrics.ELECTRICITY_PRODUCTION_ENERGY_EQ_ON_DAY]: "common.metrics.electricityProductionEnergyEqOnDay",
      [RetailMetrics.ELECTRICITY_PRODUCTION_ENERGY_EQ_ON_INTERVAL]: "common.metrics.electricityProductionEnergyEqOnInterval",
      [RetailMetrics.ELECTRICITY_PRODUCTION_ENERGY_EQ_PER_SQM]: "common.metrics.electricityProductionEnergyEqPerSqm",
      [RetailMetrics.ELECTRICITY_PRODUCTION_PER_SQM]: "common.metrics.electricityProductionPerSqm",
      [RetailMetrics.HUMIDITY_LEVEL_SCORE]: "common.metrics.humidityLevelScore",
      [RetailMetrics.LUMINOSITY]: "common.metrics.luminosity",
      [RetailMetrics.LUMINOSITY_SCORE]: "common.metrics.luminosityScore",
      [RetailMetrics.PM10_LEVEL]: "common.metrics.pm10Level",
      [RetailMetrics.PM10_LEVEL_SCORE]: "common.metrics.pm10LevelScore",
      [RetailMetrics.PM2_5_LEVEL]: "common.metrics.pm25Level",
      [RetailMetrics.PM2_5_LEVEL_SCORE]: "common.metrics.pm25LevelScore",
      [RetailMetrics.PRESSURE]: "common.metrics.pressure",
      [RetailMetrics.PRESSURE_SCORE]: "common.metrics.pressureScore",
      [RetailMetrics.TEMPERATURE_SCORE]: "common.metrics.temperatureScore",
      [RetailMetrics.TVOC]: "common.metrics.tvoc",
      [RetailMetrics.TVOC_SCORE]: "common.metrics.tvocScore",
      [RetailMetrics.WATER_CONSUMPTION]: "common.metrics.waterConsumption",
      [RetailMetrics.WATER_CONSUMPTION_CO2_EQ]: "common.metrics.waterConsumptionCo2Eq",
      [RetailMetrics.WATER_CONSUMPTION_CO2_EQ_PER_SQM]: "common.metrics.waterConsumptionCo2EqPerSqm",
      [RetailMetrics.WATER_CONSUMPTION_ENERGY_EQ]: "common.metrics.waterConsumptionEnergyEq",
      [RetailMetrics.WATER_CONSUMPTION_ENERGY_EQ_PER_SQM]: "common.metrics.waterConsumptionEnergyEqPerSqm",
      [RetailMetrics.WATER_CONSUMPTION_PER_SQM]: "common.metrics.waterConsumptionPerSqm",
      [RetailMetrics.WATER_CONSUMPTION_CO2_EQ_ON_DAY]: "common.metrics.waterConsumptionCo2EqOnDay",
      [RetailMetrics.WATER_CONSUMPTION_CO2_EQ_ON_INTERVAL]: "common.metrics.waterConsumptionCo2EqOnInterval",
      [RetailMetrics.WATER_CONSUMPTION_ENERGY_EQ_ON_DAY]: "common.metrics.waterConsumptionEnergyEqOnDay",
      [RetailMetrics.WATER_CONSUMPTION_ENERGY_EQ_ON_INTERVAL]: "common.metrics.waterConsumptionEnergyEqOnInterval",
      [RetailMetrics.WATER_CONSUMPTION_ON_DAY]: "common.metrics.waterConsumptionOnDay",
      [RetailMetrics.WATER_CONSUMPTION_ON_INTERVAL]: "common.metrics.waterConsumptionOnInterval",
      [RetailMetrics.NUMBER_OF_REFUSAL]: "common.metrics.numberOfRefusal",
      [RetailMetrics.REFUSAL_RATE]: "common.metrics.refusalRate",
    },
    kpiMetrics: {
      [RetailMetrics.DWELL]: "common.kpiMetrics.dwell",
      [RetailMetrics.CAPTURE_RATE]: "common.kpiMetrics.captureRate",
      [RetailMetrics.POPULARITY]: "common.kpiMetrics.popularity",
      [RetailMetrics.AFFLUENCE_IN]: "common.kpiMetrics.affluenceIn",
      [RetailMetrics.AFFLUENCE_OUT]: "common.kpiMetrics.affluenceOut",
      [RetailMetrics.INSIDE]: "common.kpiMetrics.inside",
      [RetailMetrics.GROUP_SIZE_IN]: "common.kpiMetrics.groupSizeIn",
      [RetailMetrics.GROUP_SIZE_OUT]: "common.kpiMetrics.groupSizeOut",
      [RetailMetrics.N_GROUPS_IN]: "common.kpiMetrics.nGroupIn",
      [RetailMetrics.N_GROUPS_OUT]: "common.kpiMetrics.nGroupOut",
      [RetailMetrics.OCCUPANCY]: "common.kpiMetrics.occupancy",
      [RetailMetrics.AFFLUENCE_IN_QUEUE]: "common.kpiMetrics.affluenceInQueue",
      [RetailMetrics.AFFLUENCE_OUT_QUEUE]: "common.kpiMetrics.affluenceOutQueue",
      [RetailMetrics.INSIDE_QUEUE]: "common.kpiMetrics.insideQueue",
      [RetailMetrics.DWELL_QUEUE]: "common.kpiMetrics.dwellQueue",
      [RetailMetrics.ABANDON_RATE_QUEUE]: "common.kpiMetrics.abandonRateQueue",
      [RetailMetrics.TEMPERATURE]: "common.kpiMetrics.temperature",
      [RetailMetrics.HUMIDITY_LEVEL]: "common.kpiMetrics.humidity",
      [RetailMetrics.CO2_LEVEL]: "common.kpiMetrics.co2",
      [RetailMetrics.GROUP_PERCENTAGE]: "common.kpiMetrics.groupPercentage",
      [RetailMetrics.OCCUPANCY_STATUS]: "common.kpiMetrics.occupancyStatus",
      [RetailMetrics.DEVICE_STATUS]: "common.kpiMetrics.deviceStatus",
      [RetailMetrics.DENSITY]: "common.kpiMetrics.density",
      [RetailMetrics.REVERSE_DENSITY]: "common.kpiMetrics.reverseDensity",
      [RetailMetrics.WAITING_TIME]: "common.kpiMetrics.waitingTime",
      [RetailMetrics.LIMIT]: "common.kpiMetrics.limit",
      ENTRANCES: "common.kpiMetrics.entrances",
      PRODUCT: "common.kpiMetrics.product",
      [RetailMetrics.BINARY_OCCUPANCY]: "common.kpiMetrics.binaryOccupancy",
      [`${RetailMetrics.BINARY_OCCUPANCY}_FREE`]: "common.kpiMetrics.binaryOccupancyFree",
      [`${RetailMetrics.BINARY_OCCUPANCY}_OCCUPIED`]: "common.kpiMetrics.binaryOccupancyOccupied",
      [RetailMetrics.CO2_LEVEL_SCORE]: "common.kpiMetrics.co2LevelScore",
      [RetailMetrics.COMBUSTIBLE_CONSUMPTION]: "common.kpiMetrics.combustibleConsumption",
      [RetailMetrics.COMBUSTIBLE_CONSUMPTION_CO2_EQ]: "common.kpiMetrics.combustibleConsumptionCo2Eq",
      [RetailMetrics.COMBUSTIBLE_CONSUMPTION_CO2_EQ_PER_SQM]: "common.kpiMetrics.combustibleConsumptionCo2EqPerSqm",
      [RetailMetrics.COMBUSTIBLE_CONSUMPTION_ENERGY_EQ]: "common.kpiMetrics.combustibleConsumptionEnergyEq",
      [RetailMetrics.COMBUSTIBLE_CONSUMPTION_ENERGY_EQ_PER_SQM]: "common.kpiMetrics.combustibleConsumptionEnergyEqPerSqm",
      [RetailMetrics.COMBUSTIBLE_CONSUMPTION_PER_SQM]: "common.kpiMetrics.combustibleConsumptionPerSqm",
      [RetailMetrics.COMFORT_SCORE]: "common.kpiMetrics.comfortScore",
      [RetailMetrics.ELECTRICITY_CONSUMPTION]: "common.kpiMetrics.electricityConsumption",
      [RetailMetrics.ELECTRICITY_CONSUMPTION_CO2_EQ]: "common.kpiMetrics.electricityConsumptionCo2Eq",
      [RetailMetrics.ELECTRICITY_CONSUMPTION_CO2_EQ_PER_SQM]: "common.kpiMetrics.electricityConsumptionCo2EqPerSqm",
      [RetailMetrics.ELECTRICITY_CONSUMPTION_ENERGY_EQ]: "common.kpiMetrics.electricityConsumptionEnergyEq",
      [RetailMetrics.ELECTRICITY_CONSUMPTION_ENERGY_EQ_PER_SQM]: "common.kpiMetrics.electricityConsumptionEnergyEqPerSqm",
      [RetailMetrics.ELECTRICITY_CONSUMPTION_PER_SQM]: "common.kpiMetrics.electricityConsumptionPerSqm",
      [RetailMetrics.ELECTRICITY_PRODUCTION]: "common.kpiMetrics.electricityProduction",
      [RetailMetrics.ELECTRICITY_PRODUCTION_CO2_EQ]: "common.kpiMetrics.electricityProductionCo2Eq",
      [RetailMetrics.ELECTRICITY_PRODUCTION_CO2_EQ_PER_SQM]: "common.kpiMetrics.electricityProductionCo2EqPerSqm",
      [RetailMetrics.ELECTRICITY_PRODUCTION_ENERGY_EQ]: "common.kpiMetrics.electricityProductionEnergyEq",
      [RetailMetrics.ELECTRICITY_PRODUCTION_ENERGY_EQ_PER_SQM]: "common.kpiMetrics.electricityProductionEnergyEqPerSqm",
      [RetailMetrics.ELECTRICITY_PRODUCTION_PER_SQM]: "common.kpiMetrics.electricityProductionPerSqm",
      [RetailMetrics.HUMIDITY_LEVEL_SCORE]: "common.kpiMetrics.humidityLevelScore",
      [RetailMetrics.LUMINOSITY]: "common.kpiMetrics.luminosity",
      [RetailMetrics.LUMINOSITY_SCORE]: "common.kpiMetrics.luminosityScore",
      [RetailMetrics.PM10_LEVEL]: "common.kpiMetrics.pm10Level",
      [RetailMetrics.PM10_LEVEL_SCORE]: "common.kpiMetrics.pm10LevelScore",
      [RetailMetrics.PM2_5_LEVEL]: "common.kpiMetrics.pm25Level",
      [RetailMetrics.PM2_5_LEVEL_SCORE]: "common.kpiMetrics.pm25LevelScore",
      [RetailMetrics.PRESSURE]: "common.kpiMetrics.pressure",
      [RetailMetrics.PRESSURE_SCORE]: "common.kpiMetrics.pressureScore",
      [RetailMetrics.TEMPERATURE_SCORE]: "common.kpiMetrics.temperatureScore",
      [RetailMetrics.TVOC]: "common.kpiMetrics.tvoc",
      [RetailMetrics.TVOC_SCORE]: "common.kpiMetrics.tvocScore",
      [RetailMetrics.WATER_CONSUMPTION]: "common.kpiMetrics.waterConsumption",
      [RetailMetrics.WATER_CONSUMPTION_CO2_EQ]: "common.kpiMetrics.waterConsumptionCo2Eq",
      [RetailMetrics.WATER_CONSUMPTION_CO2_EQ_PER_SQM]: "common.kpiMetrics.waterConsumptionCo2EqPerSqm",
      [RetailMetrics.WATER_CONSUMPTION_ENERGY_EQ]: "common.kpiMetrics.waterConsumptionEnergyEq",
      [RetailMetrics.WATER_CONSUMPTION_ENERGY_EQ_PER_SQM]: "common.kpiMetrics.waterConsumptionEnergyEqPerSqm",
      [RetailMetrics.WATER_CONSUMPTION_PER_SQM]: "common.kpiMetrics.waterConsumptionPerSqm",
      [RetailMetrics.WATER_CONSUMPTION_CO2_EQ_ON_DAY]: "common.kpiMetrics.waterConsumptionCo2EqOnDay",
      [RetailMetrics.WATER_CONSUMPTION_CO2_EQ_ON_INTERVAL]: "common.kpiMetrics.waterConsumptionCo2EqOnInterval",
      [RetailMetrics.WATER_CONSUMPTION_ENERGY_EQ_ON_DAY]: "common.kpiMetrics.waterConsumptionEnergyEqOnDay",
      [RetailMetrics.WATER_CONSUMPTION_ENERGY_EQ_ON_INTERVAL]: "common.kpiMetrics.waterConsumptionEnergyEqOnInterval",
      [RetailMetrics.WATER_CONSUMPTION_ON_DAY]: "common.kpiMetrics.waterConsumptionOnDay",
      [RetailMetrics.WATER_CONSUMPTION_ON_INTERVAL]: "common.kpiMetrics.waterConsumptionOnInterval",
      [RetailMetrics.ELECTRICITY_CONSUMPTION_ON_DAY]: "common.kpiMetrics.electricityConsumptionOnDay",
      [RetailMetrics.ELECTRICITY_CONSUMPTION_ON_INTERVAL]: "common.kpiMetrics.electricityConsumptionOnInterval",
      [RetailMetrics.ELECTRICITY_CONSUMPTION_CO2_EQ_ON_DAY]: "common.kpiMetrics.electricityConsumptionCo2EqOnDay",
      [RetailMetrics.ELECTRICITY_CONSUMPTION_CO2_EQ_ON_INTERVAL]: "common.kpiMetrics.electricityConsumptionCo2EqOnInterval",
      [RetailMetrics.ELECTRICITY_CONSUMPTION_ENERGY_EQ_ON_DAY]: "common.kpiMetrics.electricityConsumptionEnergyEqOnDay",
      [RetailMetrics.ELECTRICITY_CONSUMPTION_ENERGY_EQ_ON_INTERVAL]: "common.kpiMetrics.electricityConsumptionEnergyEqOnInterval",
      [RetailMetrics.ELECTRICITY_PRODUCTION_ON_DAY]: "common.kpiMetrics.electricityProductionOnDay",
      [RetailMetrics.ELECTRICITY_PRODUCTION_ON_INTERVAL]: "common.kpiMetrics.electricityProductionOnInterval",
      [RetailMetrics.ELECTRICITY_PRODUCTION_CO2_EQ_ON_DAY]: "common.kpiMetrics.electricityProductionCo2EqOnDay",
      [RetailMetrics.ELECTRICITY_PRODUCTION_CO2_EQ_ON_INTERVAL]: "common.kpiMetrics.electricityProductionCo2EqOnInterval",
      [RetailMetrics.ELECTRICITY_PRODUCTION_ENERGY_EQ_ON_DAY]: "common.kpiMetrics.electricityProductionEnergyEqOnDay",
      [RetailMetrics.ELECTRICITY_PRODUCTION_ENERGY_EQ_ON_INTERVAL]: "common.kpiMetrics.electricityProductionEnergyEqOnInterval",
      [RetailMetrics.NUMBER_OF_REFUSAL]: "common.kpiMetrics.numberOfRefusal",
      [RetailMetrics.REFUSAL_RATE]: "common.kpiMetrics.refusalRate",
    },
    noOptionsFound: "common.noOptionsFound",
    noUserName: "common.noUserName",
    device: "common.device",
    title: "common.title",
    noName: "common.noName",
    noTooltip: "common.noTooltip",
    reportGenerator: "common.reportGenerator",
    logout: "common.logout",
    date: "common.date",
    discontinuousDate: "common.discontinuousDate",
    ageCategory: "common.ageCategory",
    limit: "common.limit",
    elecOrigin: "common.elecOrigin",
    elecDestination: "common.elecDestination",
    combustibleType: "common.combustibleType",
    waterTemperature: "common.waterTemperature",
    elecUse: "common.elecUse",
    waterUse: "common.waterUse",
    combustibleUse: "common.combustibleUse",
    tagCategory: "common.tagCategory",
    tag: "common.tag",
    zone: "common.zone",
    zoneId: "common.zoneId",
    deletedZone: "common.deletedZone",
    all: "common.all",
    gender: "common.gender",
    passage: "common.passage",
    noDataDefault: "common.noDataDefault",
    noTag: "common.noTag",
    complementary: "common.complementary",
    engagement: "common.engagement",
    groupSize: "common.groupSize",
    visitors: "common.visitors",
    peopleUnit: "common.peopleUnit",
    helpCenter: "common.helpCenter",
    settings: "common.settings",
    loading: "common.loading",
    launchTutorial: "common.launchTutorial",
    reload: "common.reload",
    details: "common.details",
    fields: {
      user: "common.fields.user",
      action: "common.fields.action",
      type: "common.fields.type",
      date: "common.fields.date",
      creationDate: "common.fields.creationDate",
      installations: "common.fields.installations",
      address: "common.fields.address",
    },
    weekday: "common.weekday",
    month: "common.month",
    name: "common.name",
    time: {
      minutes: "common.time.minutes",
      months: {
        [Month.JANUARY]: {
          long: "common.time.months.january.long",
        },
        [Month.FEBRUARY]: {
          long: "common.time.months.february.long",
        },
        [Month.MARCH]: {
          long: "common.time.months.march.long",
        },
        [Month.APRIL]: {
          long: "common.time.months.april.long",
        },
        [Month.MAY]: {
          long: "common.time.months.may.long",
        },
        [Month.JUNE]: {
          long: "common.time.months.june.long",
        },
        [Month.JULY]: {
          long: "common.time.months.july.long",
        },
        [Month.AUGUST]: {
          long: "common.time.months.august.long",
        },
        [Month.SEPTEMBER]: {
          long: "common.time.months.september.long",
        },
        [Month.OCTOBER]: {
          long: "common.time.months.october.long",
        },
        [Month.NOVEMBER]: {
          long: "common.time.months.november.long",
        },
        [Month.DECEMBER]: {
          long: "common.time.months.december.long",
        },
      },
      days: {
        [Weekdays.MONDAY]: {
          letter: "common.time.days.monday.letter",
          short: "common.time.days.monday.short",
          long: "common.time.days.monday.long",
        },
        [Weekdays.TUESDAY]: {
          letter: "common.time.days.tuesday.letter",
          short: "common.time.days.tuesday.short",
          long: "common.time.days.tuesday.long",
        },
        [Weekdays.WEDNESDAY]: {
          letter: "common.time.days.wednesday.letter",
          short: "common.time.days.wednesday.short",
          long: "common.time.days.wednesday.long",
        },
        [Weekdays.THURSDAY]: {
          letter: "common.time.days.thursday.letter",
          short: "common.time.days.thursday.short",
          long: "common.time.days.thursday.long",
        },
        [Weekdays.FRIDAY]: {
          letter: "common.time.days.friday.letter",
          short: "common.time.days.friday.short",
          long: "common.time.days.friday.long",
        },
        [Weekdays.SATURDAY]: {
          letter: "common.time.days.saturday.letter",
          short: "common.time.days.saturday.short",
          long: "common.time.days.saturday.long",
        },
        [Weekdays.SUNDAY]: {
          letter: "common.time.days.sunday.letter",
          short: "common.time.days.sunday.short",
          long: "common.time.days.sunday.long",
        },
      },
      // Move every translations associated to time here (Generic terms only),
    },
    units: {
      [RetailMetrics.AFFLUENCE_IN]: `common.units.${RetailMetrics.AFFLUENCE_IN}`,
      [RetailMetrics.AFFLUENCE_OUT]: `common.units.${RetailMetrics.AFFLUENCE_OUT}`,
      [RetailMetrics.CAPTURE_RATE]: `common.units.${RetailMetrics.CAPTURE_RATE}`,
      [RetailMetrics.POPULARITY]: `common.units.${RetailMetrics.POPULARITY}`,
      [RetailMetrics.OCCUPANCY]: `common.units.${RetailMetrics.OCCUPANCY}`,
      [RetailMetrics.ABANDON_RATE_QUEUE]: `common.units.${RetailMetrics.ABANDON_RATE_QUEUE}`,
      [RetailMetrics.DWELL]: `common.units.${RetailMetrics.DWELL}`,
      [RetailMetrics.DWELL_QUEUE]: `common.units.${RetailMetrics.DWELL_QUEUE}`,
      [RetailMetrics.INSIDE]: `common.units.${RetailMetrics.INSIDE}`,
      [RetailMetrics.AFFLUENCE_IN_QUEUE]: `common.units.${RetailMetrics.AFFLUENCE_IN_QUEUE}`,
      [RetailMetrics.AFFLUENCE_OUT_QUEUE]: `common.units.${RetailMetrics.AFFLUENCE_OUT_QUEUE}`,
      [RetailMetrics.N_GROUPS_OUT]: `common.units.${RetailMetrics.N_GROUPS_OUT}`,
      [RetailMetrics.N_GROUPS_IN]: `common.units.${RetailMetrics.N_GROUPS_IN}`,
      [RetailMetrics.INSIDE_QUEUE]: `common.units.${RetailMetrics.INSIDE_QUEUE}`,
      [RetailMetrics.GROUP_SIZE_OUT]: `common.units.${RetailMetrics.GROUP_SIZE_OUT}`,
      [RetailMetrics.GROUP_SIZE_IN]: `common.units.${RetailMetrics.GROUP_SIZE_IN}`,
      [RetailMetrics.TEMPERATURE]: `common.units.${RetailMetrics.TEMPERATURE}`,
      [RetailMetrics.HUMIDITY_LEVEL]: `common.units.${RetailMetrics.HUMIDITY_LEVEL}`,
      [RetailMetrics.CO2_LEVEL]: `common.units.${RetailMetrics.CO2_LEVEL}`,
      [RetailMetrics.DEVICE_STATUS]: `common.units.${RetailMetrics.DEVICE_STATUS}`,
      [RetailMetrics.GROUP_PERCENTAGE]: `common.units.${RetailMetrics.GROUP_PERCENTAGE}`,
      [RetailMetrics.OCCUPANCY_STATUS]: `common.units.${RetailMetrics.OCCUPANCY_STATUS}`,
      [RetailMetrics.DENSITY]: `common.units.${RetailMetrics.DENSITY}`,
      [RetailMetrics.REVERSE_DENSITY]: `common.units.${RetailMetrics.REVERSE_DENSITY}`,
      [RetailMetrics.WAITING_TIME]: `common.units.${RetailMetrics.WAITING_TIME}`,
      [RetailMetrics.LIMIT]: `common.units.${RetailMetrics.LIMIT}`,
      [RetailMetrics.BINARY_OCCUPANCY]: `common.units.${RetailMetrics.BINARY_OCCUPANCY}`,
      [RetailMetrics.TVOC]: `common.units.${RetailMetrics.TVOC}`,
      [RetailMetrics.PM2_5_LEVEL]: `common.units.${RetailMetrics.PM2_5_LEVEL}`,
      [RetailMetrics.PM10_LEVEL]: `common.units.${RetailMetrics.PM10_LEVEL}`,
      [RetailMetrics.LUMINOSITY]: `common.units.${RetailMetrics.LUMINOSITY}`,
      [RetailMetrics.PRESSURE]: `common.units.${RetailMetrics.PRESSURE}`,
      [RetailMetrics.COMFORT_SCORE]: `common.units.${RetailMetrics.COMFORT_SCORE}`,
      [RetailMetrics.CO2_LEVEL_SCORE]: `common.units.${RetailMetrics.CO2_LEVEL_SCORE}`,
      [RetailMetrics.HUMIDITY_LEVEL_SCORE]: `common.units.${RetailMetrics.HUMIDITY_LEVEL_SCORE}`,
      [RetailMetrics.TEMPERATURE_SCORE]: `common.units.${RetailMetrics.TEMPERATURE_SCORE}`,
      [RetailMetrics.TVOC_SCORE]: `common.units.${RetailMetrics.TVOC_SCORE}`,
      [RetailMetrics.PM2_5_LEVEL_SCORE]: `common.units.${RetailMetrics.PM2_5_LEVEL_SCORE}`,
      [RetailMetrics.PM10_LEVEL_SCORE]: `common.units.${RetailMetrics.PM10_LEVEL_SCORE}`,
      [RetailMetrics.LUMINOSITY_SCORE]: `common.units.${RetailMetrics.LUMINOSITY_SCORE}`,
      [RetailMetrics.PRESSURE_SCORE]: `common.units.${RetailMetrics.PRESSURE_SCORE}`,
      [RetailMetrics.ELECTRICITY_PRODUCTION]: `common.units.${RetailMetrics.ELECTRICITY_PRODUCTION}`,
      [RetailMetrics.ELECTRICITY_CONSUMPTION]: `common.units.${RetailMetrics.ELECTRICITY_CONSUMPTION}`,
      [RetailMetrics.ELECTRICITY_PRODUCTION_PER_SQM]: `common.units.${RetailMetrics.ELECTRICITY_PRODUCTION_PER_SQM}`,
      [RetailMetrics.ELECTRICITY_PRODUCTION_ENERGY_EQ]: `common.units.${RetailMetrics.ELECTRICITY_PRODUCTION_ENERGY_EQ}`,
      [RetailMetrics.ELECTRICITY_PRODUCTION_ENERGY_EQ_PER_SQM]: `common.units.${RetailMetrics.ELECTRICITY_PRODUCTION_ENERGY_EQ_PER_SQM}`,
      [RetailMetrics.ELECTRICITY_PRODUCTION_CO2_EQ]: `common.units.${RetailMetrics.ELECTRICITY_PRODUCTION_CO2_EQ}`,
      [RetailMetrics.ELECTRICITY_PRODUCTION_CO2_EQ_PER_SQM]: `common.units.${RetailMetrics.ELECTRICITY_PRODUCTION_CO2_EQ_PER_SQM}`,
      [RetailMetrics.ELECTRICITY_CONSUMPTION_PER_SQM]: `common.units.${RetailMetrics.ELECTRICITY_CONSUMPTION_PER_SQM}`,
      [RetailMetrics.ELECTRICITY_CONSUMPTION_ENERGY_EQ]: `common.units.${RetailMetrics.ELECTRICITY_CONSUMPTION_ENERGY_EQ}`,
      [RetailMetrics.ELECTRICITY_CONSUMPTION_ENERGY_EQ_PER_SQM]: `common.units.${RetailMetrics.ELECTRICITY_CONSUMPTION_ENERGY_EQ_PER_SQM}`,
      [RetailMetrics.ELECTRICITY_CONSUMPTION_CO2_EQ]: `common.units.${RetailMetrics.ELECTRICITY_CONSUMPTION_CO2_EQ}`,
      [RetailMetrics.ELECTRICITY_CONSUMPTION_CO2_EQ_PER_SQM]: `common.units.${RetailMetrics.ELECTRICITY_CONSUMPTION_CO2_EQ_PER_SQM}`,
      [RetailMetrics.WATER_CONSUMPTION]: `common.units.${RetailMetrics.WATER_CONSUMPTION}`,
      [RetailMetrics.WATER_CONSUMPTION_PER_SQM]: `common.units.${RetailMetrics.WATER_CONSUMPTION_PER_SQM}`,
      [RetailMetrics.WATER_CONSUMPTION_ENERGY_EQ]: `common.units.${RetailMetrics.WATER_CONSUMPTION_ENERGY_EQ}`,
      [RetailMetrics.WATER_CONSUMPTION_ENERGY_EQ_PER_SQM]: `common.units.${RetailMetrics.WATER_CONSUMPTION_ENERGY_EQ_PER_SQM}`,
      [RetailMetrics.WATER_CONSUMPTION_CO2_EQ]: `common.units.${RetailMetrics.WATER_CONSUMPTION_CO2_EQ}`,
      [RetailMetrics.WATER_CONSUMPTION_CO2_EQ_PER_SQM]: `common.units.${RetailMetrics.WATER_CONSUMPTION_CO2_EQ_PER_SQM}`,
      [RetailMetrics.COMBUSTIBLE_CONSUMPTION]: `common.units.${RetailMetrics.COMBUSTIBLE_CONSUMPTION}`,
      [RetailMetrics.COMBUSTIBLE_CONSUMPTION_PER_SQM]: `common.units.${RetailMetrics.COMBUSTIBLE_CONSUMPTION_PER_SQM}`,
      [RetailMetrics.COMBUSTIBLE_CONSUMPTION_ENERGY_EQ]: `common.units.${RetailMetrics.COMBUSTIBLE_CONSUMPTION_ENERGY_EQ}`,
      [RetailMetrics.COMBUSTIBLE_CONSUMPTION_ENERGY_EQ_PER_SQM]: `common.units.${RetailMetrics.COMBUSTIBLE_CONSUMPTION_ENERGY_EQ_PER_SQM}`,
      [RetailMetrics.COMBUSTIBLE_CONSUMPTION_CO2_EQ]: `common.units.${RetailMetrics.COMBUSTIBLE_CONSUMPTION_CO2_EQ}`,
      [RetailMetrics.ELECTRICITY_PRODUCTION_ON_DAY]: `common.units.${RetailMetrics.ELECTRICITY_PRODUCTION_ON_DAY}`,
      [RetailMetrics.ELECTRICITY_PRODUCTION_ON_INTERVAL]: `common.units.${RetailMetrics.ELECTRICITY_PRODUCTION_ON_INTERVAL}`,
      [RetailMetrics.ELECTRICITY_CONSUMPTION_ON_DAY]: `common.units.${RetailMetrics.ELECTRICITY_CONSUMPTION_ON_DAY}`,
      [RetailMetrics.ELECTRICITY_CONSUMPTION_ON_INTERVAL]: `common.units.${RetailMetrics.ELECTRICITY_CONSUMPTION_ON_INTERVAL}`,
      [RetailMetrics.ELECTRICITY_PRODUCTION_ENERGY_EQ_ON_DAY]: `common.units.${RetailMetrics.ELECTRICITY_PRODUCTION_ENERGY_EQ_ON_DAY}`,
      [RetailMetrics.ELECTRICITY_PRODUCTION_ENERGY_EQ_ON_INTERVAL]: `common.units.${RetailMetrics.ELECTRICITY_PRODUCTION_ENERGY_EQ_ON_INTERVAL}`,
      [RetailMetrics.ELECTRICITY_PRODUCTION_CO2_EQ_ON_DAY]: `common.units.${RetailMetrics.ELECTRICITY_PRODUCTION_CO2_EQ_ON_DAY}`,
      [RetailMetrics.ELECTRICITY_PRODUCTION_CO2_EQ_ON_INTERVAL]: `common.units.${RetailMetrics.ELECTRICITY_PRODUCTION_CO2_EQ_ON_INTERVAL}`,
      [RetailMetrics.ELECTRICITY_CONSUMPTION_ENERGY_EQ_ON_DAY]: `common.units.${RetailMetrics.ELECTRICITY_CONSUMPTION_ENERGY_EQ_ON_DAY}`,
      [RetailMetrics.ELECTRICITY_CONSUMPTION_ENERGY_EQ_ON_INTERVAL]: `common.units.${RetailMetrics.ELECTRICITY_CONSUMPTION_ENERGY_EQ_ON_INTERVAL}`,
      [RetailMetrics.ELECTRICITY_CONSUMPTION_CO2_EQ_ON_DAY]: `common.units.${RetailMetrics.ELECTRICITY_CONSUMPTION_CO2_EQ_ON_DAY}`,
      [RetailMetrics.ELECTRICITY_CONSUMPTION_CO2_EQ_ON_INTERVAL]: `common.units.${RetailMetrics.ELECTRICITY_CONSUMPTION_CO2_EQ_ON_INTERVAL}`,
      [RetailMetrics.WATER_CONSUMPTION_CO2_EQ_ON_DAY]: `common.units.${RetailMetrics.WATER_CONSUMPTION_CO2_EQ_ON_DAY}`,
      [RetailMetrics.WATER_CONSUMPTION_CO2_EQ_ON_INTERVAL]: `common.units.${RetailMetrics.WATER_CONSUMPTION_CO2_EQ_ON_INTERVAL}`,
      [RetailMetrics.WATER_CONSUMPTION_ENERGY_EQ_ON_DAY]: `common.units.${RetailMetrics.WATER_CONSUMPTION_ENERGY_EQ_ON_DAY}`,
      [RetailMetrics.WATER_CONSUMPTION_ENERGY_EQ_ON_INTERVAL]: `common.units.${RetailMetrics.WATER_CONSUMPTION_ENERGY_EQ_ON_INTERVAL}`,
      [RetailMetrics.WATER_CONSUMPTION_ON_DAY]: `common.units.${RetailMetrics.WATER_CONSUMPTION_ON_DAY}`,
      [RetailMetrics.WATER_CONSUMPTION_ON_INTERVAL]: `common.units.${RetailMetrics.WATER_CONSUMPTION_ON_INTERVAL}`,
      [RetailMetrics.COMBUSTIBLE_CONSUMPTION_CO2_EQ_PER_SQM]: `common.units.${RetailMetrics.COMBUSTIBLE_CONSUMPTION_CO2_EQ_PER_SQM}`,
      [RetailMetrics.NUMBER_OF_REFUSAL]: `common.units.${RetailMetrics.NUMBER_OF_REFUSAL}`,
      [RetailMetrics.REFUSAL_RATE]: `common.units.${RetailMetrics.REFUSAL_RATE}`,
    },
    scores: {
      COMFORT_SCORE: {
        1: "common.scores.COMFORT_SCORE.1",
        2: "common.scores.COMFORT_SCORE.2",
        3: "common.scores.COMFORT_SCORE.3",
        4: "common.scores.COMFORT_SCORE.4",
        5: "common.scores.COMFORT_SCORE.5",
      },
      GENERAL_SCORE: {
        1: "common.scores.GENERAL_SCORE.1",
        2: "common.scores.GENERAL_SCORE.2",
        3: "common.scores.GENERAL_SCORE.3",
      },
    },
  },
  dashboardV2: {
    kpis: {
      flow: {
        title: "dashboardV2.kpis.flow.title",
        tooltip: "dashboardV2.kpis.flow.tooltip",
        kpis: {
          inside: {
            description: "dashboardV2.kpis.flow.kpis.inside.description",
            unit: "dashboardV2.kpis.flow.kpis.inside.unit",
          },
          today: {
            description: "dashboardV2.kpis.flow.kpis.today.description",
            unit: "dashboardV2.kpis.flow.kpis.today.unit",
          },
          dwell: {
            description: "dashboardV2.kpis.flow.kpis.dwell.description",
            unit: "dashboardV2.kpis.flow.kpis.dwell.unit",
          },
        },
      },
      comfort: {
        title: "dashboardV2.kpis.comfort.title",
        tooltip: "dashboardV2.kpis.comfort.tooltip",
        kpis: {
          good: {
            description: "dashboardV2.kpis.comfort.kpis.good.description",
            unit: "dashboardV2.kpis.comfort.kpis.good.unit",
          },
          average: {
            description: "dashboardV2.kpis.comfort.kpis.average.description",
            unit: "dashboardV2.kpis.comfort.kpis.average.unit",
          },
          critical: {
            description: "dashboardV2.kpis.comfort.kpis.critical.description",
            unit: "dashboardV2.kpis.comfort.kpis.critical.unit",
          },
        },
      },
      behavior: {
        title: "dashboardV2.kpis.behavior.title",
        tooltip: "dashboardV2.kpis.behavior.tooltip",
        kpis: {
          product: {
            description: "dashboardV2.kpis.behavior.kpis.product.description",
            unit: "dashboardV2.kpis.behavior.kpis.product.unit",
          },
          femaleVisitorProportion: {
            description: "dashboardV2.kpis.behavior.kpis.femaleVisitorProportion.description",
            unit: "dashboardV2.kpis.behavior.kpis.femaleVisitorProportion.unit",
          },
          captureRate: {
            description: "dashboardV2.kpis.behavior.kpis.captureRate.description",
            unit: "dashboardV2.kpis.behavior.kpis.captureRate.unit",
          },
        },
      },
      energy: {
        title: "dashboardV2.kpis.energy.title",
        tooltip: "dashboardV2.kpis.energy.tooltip",
        kpis: {
          elecConsommation: {
            description: "dashboardV2.kpis.energy.kpis.elecConsommation.description",
            unit: "dashboardV2.kpis.energy.kpis.elecConsommation.unit",
          },
          elecProduction: {
            description: "dashboardV2.kpis.energy.kpis.elecProduction.description",
            unit: "dashboardV2.kpis.energy.kpis.elecProduction.unit",
          },
          waterConsommation: {
            description: "dashboardV2.kpis.energy.kpis.waterConsommation.description",
            unit: "dashboardV2.kpis.energy.kpis.waterConsommation.unit",
          },
        },
      },
    },
  },
  dashboard: {
    hello: "dashboard.hello",
    title: {
      sensorCount: "dashboard.title.sensorCount",
      shortcuts: "dashboard.title.shortcuts",
      activityLog: "dashboard.title.activityLog",
    },
    shortcuts: {
      tutorial: "dashboard.shortcuts.tutorial",
    },
    stats: {
      installationCount: "dashboard.stats.installationCount",
      userCount: "dashboard.stats.userCount",
      totalDeviceCount: "dashboard.stats.totalDeviceCount",
      counting: "dashboard.stats.counting",
      airQuality: "dashboard.stats.airQuality",
    },
  },
  filters: {
    label: "filters.label",
    name: {
      events: "filters.name.events",
      timespan: "filters.name.timespan",
      zone: "filters.name.zone",
      hour: "filters.name.hour",
      day: "filters.name.day",
    },
    days: {
      mondays: "filters.days.mondays",
      tuesdays: "filters.days.tuesdays",
      wednesdays: "filters.days.wednesdays",
      thursdays: "filters.days.thursdays",
      fridays: "filters.days.fridays",
      saturdays: "filters.days.saturdays",
      sundays: "filters.days.sundays",
    },
    hoursOptions: {
      morning: "filters.hoursOptions.morning",
      afternoon: "filters.hoursOptions.afternoon",
      openingHours: "filters.hoursOptions.openingHours",
    },
    timespan: {
      today: "filters.timespan.today",
      lastWeek: "filters.timespan.lastWeek",
      last2Weeks: "filters.timespan.last2Weeks",
      lastMonth: "filters.timespan.lastMonth",
      lastQuarter: "filters.timespan.lastQuarter",
      custom: "filters.timespan.custom",
      emptyFieldErrorMessage: "filters.timespan.emptyFieldErrorMessage",
      yesterday: "filters.timespan.yesterday",
    },
  },
  installations: {
    sidebarSection: "installations.sidebarSection",
    airQualityTitle: "installations.airQualityTitle",
    countingTitle: "installations.countingTitle",
    emptyInstallations: "installations.emptyInstallations",
    installationsLabel: "installations.installationsLabel",
    noInstallationsFound: "installations.noInstallationsFound",
    searchInputPlaceholder: "installations.searchInputPlaceholder",
    tooltipLabel: "installations.tooltipLabel",
    dropdownHeaderLabel: "installations.dropdownHeaderLabel",
    noData: {
      text: "installations.noData.text",
      subText: "installations.noData.subText",
    },
  },
  countingInstallation: {
    kpiWidget: {
      title: "countingInstallation.kpiWidget.title",
      numberOfVisitorsTooltip: "countingInstallation.kpiWidget.numberOfVisitorsTooltip",
      totalNumberOfVisitorsTooltip: "countingInstallation.kpiWidget.totalNumberOfVisitorsTooltip",
      averageTimeTooltip: "countingInstallation.kpiWidget.averageTimeTooltip",
    },
    evolutionOfVisitors: {
      title: "countingInstallation.evolutionOfVisitors.title",
      tooltip: "countingInstallation.evolutionOfVisitors.tooltip",
      chartTooltipLabel: "countingInstallation.evolutionOfVisitors.chartTooltipLabel",
    },
    visitorsPerHour: {
      title: "countingInstallation.visitorsPerHour.title",
      tooltip: "countingInstallation.visitorsPerHour.tooltip",
      chartTooltipLabel: "countingInstallation.visitorsPerHour.chartTooltipLabel",
    },
    statisticsPerDay: {
      title: "countingInstallation.statisticsPerDay.title",
      tooltip: "countingInstallation.statisticsPerDay.tooltip",
      category: "countingInstallation.statisticsPerDay.category",
      chartTooltipLabel: "countingInstallation.statisticsPerDay.chartTooltipLabel",
    },
    averageVisitorsPerDay: {
      title: "countingInstallation.averageVisitorsPerDay.title",
      tooltip: "countingInstallation.averageVisitorsPerDay.tooltip",
      chartTooltipLabel: "countingInstallation.averageVisitorsPerDay.chartTooltipLabel",
    },
    distributionPerDay: {
      title: "countingInstallation.distributionPerDay.title",
      tooltip: "countingInstallation.distributionPerDay.tooltip",
      chartTooltipLabel: "countingInstallation.distributionPerDay.chartTooltipLabel",
    },
    mostUsedZones: {
      title: "countingInstallation.mostUsedZones.title",
      tooltip: "countingInstallation.mostUsedZones.tooltip",
      chartTooltipLabel: "countingInstallation.mostUsedZones.chartTooltipLabel",
    },
    mostUsedPassages: {
      title: "countingInstallation.mostUsedPassages.title",
      tooltip: "countingInstallation.mostUsedPassages.tooltip",
      chartTooltipLabel: "countingInstallation.mostUsedPassages.chartTooltipLabel",
    },
    averageDwellTimePerDay: {
      title: "countingInstallation.averageDwellTimePerDay.title",
      tooltip: "countingInstallation.averageDwellTimePerDay.tooltip",
    },
  },
  airQualityInstallation: {
    liveKpiByZones: {
      title: "airQualityInstallation.liveKpiByZones.title",
      co2AirConcentration: "airQualityInstallation.liveKpiByZones.co2AirConcentration",
      temperatureNow: "airQualityInstallation.liveKpiByZones.temperatureNow",
      humidity: "airQualityInstallation.liveKpiByZones.humidity",
    },
    globalStatisticsPerDay: {
      title: "airQualityInstallation.globalStatisticsPerDay.title",
      tooltip: "airQualityInstallation.globalStatisticsPerDay.tooltip",
    },
    airQualityEvolutionPerDay: {
      title: "airQualityInstallation.airQualityEvolutionPerDay.title",
      tooltip: "airQualityInstallation.airQualityEvolutionPerDay.tooltip",
    },
    averageValuePerDay: {
      title: "airQualityInstallation.averageValuePerDay.title",
      tooltip: "airQualityInstallation.averageValuePerDay.tooltip",
    },
    statisticsPerKPIS: {
      title: "airQualityInstallation.statisticsPerKPIS.title",
      tooltip: "airQualityInstallation.statisticsPerKPIS.tooltip",
    },
  },
  eventMetric: {
    counting: "eventMetric.counting",
    airQuality: "eventMetric.airQuality",
  },
  login: {
    commonError: "login.commonError",
    wrongCredentials: "login.wrongCredentials",
    copyright: "login.copyright",
    welcomeTitle: "login.welcomeTitle",
    welcomeSubtitleDesktop: "login.welcomeSubtitleDesktop",
    welcomeSubtitleMobile: "login.welcomeSubtitleMobile",
    previewImageTitle: "login.previewImageTitle",
    previewImageSubtitle: "login.previewImageSubtitle",
    rememberMe: "login.rememberMe",
    graphic: {
      waitingTime: "login.graphic.waitingTime",
      thisWeek: "login.graphic.thisWeek",
      thisDay: "login.graphic.thisDay",
      visitors: "login.graphic.visitors",
      insideVisitors: "login.graphic.insideVisitors",
      insideTime: "login.graphic.insideTime",
      insideVisitorsTime: "login.graphic.insideVisitorsTime",
      occupancy: "login.graphic.occupancy",
    },
  },
  loginForm: {
    emailLabel: "loginForm.emailLabel",
    emailPlaceholder: "loginForm.emailPlaceholder",
    emailRequiredError: "loginForm.emailRequiredError",
    emailValidError: "loginForm.emailValidError",
    passwordPlaceholder: "loginForm.passwordPlaceholder",
    passwordRequiredError: "loginForm.passwordRequiredError",
    passwordValidError: "loginForm.passwordValidError",
    submitButtonText: "loginForm.submitButtonText",
    resetButtonText: "loginForm.resetButtonText",
  },
  phoneCode: {
    franceCodeName: "phoneCode.franceCodeName",
  },
  organizationForm: {
    organizationItemTitle: "organizationForm.organizationItemTitle",
    organizationItemDescription: "organizationForm.organizationItemDescription",
    addressItemTitle: "organizationForm.addressItemTitle",
    addressItemDescription: "organizationForm.addressItemDescription",
    logoTitle: "organizationForm.logoTitle",
    logoDescription: "organizationForm.logoDescription",
    addressPlaceholder: "organizationForm.addressPlaceholder",
    organizationPlaceholder: "organizationForm.organizationPlaceholder",
    organizationItemSubText: "organizationForm.organizationItemSubText",
    organizationNameError: "organizationForm.organizationNameError",
  },
  aboutYouForm: {
    organizationName: "aboutYouForm.organizationName",
    userEmail: "aboutYouForm.userEmail",
    accountNameItemTitle: "aboutYouForm.accountNameItemTitle",
    accountNameItemDescription: "aboutYouForm.accountNameItemDescription",
    phoneItemTitle: "aboutYouForm.phoneItemTitle",
    phoneItemDescription: "aboutYouForm.phoneItemDescription",
    photoItemTitle: "aboutYouForm.photoItemTitle",
    photoItemDescription: "aboutYouForm.photoItemDescription",
    firstNamePlaceholder: "aboutYouForm.firstNamePlaceholder",
    lastNamePlaceholder: "aboutYouForm.lastNamePlaceholder",
  },
  imagePicker: {
    dragAndDropLabel: "imagePicker.dragAndDropLabel",
    dividerLabel: "imagePicker.dividerLabel",
    replaceLabel: "imagePicker.replaceLabel",
    dimensionLabel: "imagePicker.dimensionLabel",
    sizeLabel: "imagePicker.sizeLabel",
    removeButtonText: "imagePicker.removeButtonText",
    changeButtonText: "imagePicker.changeButtonText",
    imageSizeError: "imagePicker.imageSizeError",
  },
  loginOtp: {
    header: "loginOtp.header",
    headerMobile: "loginOtp.headerMobile",
    description: "loginOtp.description",
    subtitle: "loginOtp.subtitle",
    resendButtonText: "loginOtp.resendButtonText",
    email: "loginOtp.email",
    phone: "loginOtp.phone",
    resend: "loginOtp.resend",
    invalidCode: "loginOtp.invalidCode",
    dontAskAgain: "loginOtp.dontAskAgain",
    goBack: "loginOtp.goBack",
    invalidCodeMessage: "loginOtp.invalidCodeMessage",
  },
  approveModal: {
    title: "approveModal.title",
    subtitle: "approveModal.subtitle",
    declineButtonText: "approveModal.declineButtonText",
    approveButtonText: "approveModal.approveButtonText",
  },
  invitationExpiredModal: {
    title: "invitationExpiredModal.title",
    adminDescription: "invitationExpiredModal.adminDescription",
    userDescription: "invitationExpiredModal.userDescription",
    resendButtonText: "invitationExpiredModal.resendButtonText",
    firstPhoneNumber: "invitationExpiredModal.firstPhoneNumber",
    secondPhoneNumber: "invitationExpiredModal.secondPhoneNumber",
    emailAddress: "invitationExpiredModal.emailAddress",
  },
  filterDropdown: {
    validateButtonLabel: "filterDropdown.validateButtonLabel",
    rangeCountLabel: "filterDropdown.rangeCountLabel",
    dateRangeUnit: "filterDropdown.dateRangeUnit",
    dateRangeUnitPlural: "filterDropdown.dateRangeUnitPlural",
    todayButtonLabel: "filterDropdown.todayButtonLabel",
    timespanInputPlaceholder: "filterDropdown.timespanInputPlaceholder",
    timespanTriggerInputText: "filterDropdown.timespanTriggerInputText",
    timespanDropdownTitle: "filterDropdown.timespanDropdownTitle",
    timespanDropdownTooltip: "filterDropdown.timespanDropdownTooltip",
    daysDropdownInputPlaceholder: "filterDropdown.daysDropdownInputPlaceholder",
    daysTriggerInputText: "filterDropdown.daysTriggerInputText",
    daysDropdownTitle: "filterDropdown.daysDropdownTitle",
    daysDropdownTooltip: "filterDropdown.daysDropdownTooltip",
    hourInputPlaceholder: "filterDropdown.hourInputPlaceholder",
    hourTriggerInputText: "filterDropdown.hourTriggerInputText",
    hourDropdownTitle: "filterDropdown.hourDropdownTitle",
    quickSearchPlaceholder: "filterDropdown.quickSearchPlaceholder",
    quickSelectPlaceholder: "filterDropdown.quickSelectPlaceholder",
    eventInputPlaceholder: "filterDropdown.eventInputPlaceholder",
    eventEmptyResult: "filterDropdown.eventEmptyResult",
    applyFilterButtonText: "filterDropdown.applyFilterButtonText",
    eventTriggerInputText: "filterDropdown.eventTriggerInputText",
    daysOperatorLabel: "filterDropdown.daysOperatorLabel",
    daysLabel: "filterDropdown.daysLabel",
    operatorInputPlaceholder: "filterDropdown.operatorInputPlaceholder",
    daysInputPlaceholder: "filterDropdown.daysInputPlaceholder",
    fromLabel: "filterDropdown.fromLabel",
    toLabel: "filterDropdown.toLabel",
    zoneTriggerInputText: "filterDropdown.zoneTriggerInputText",
    minLabel: "filterDropdown.minLabel",
    hourLabel: "filterDropdown.hourLabel",
    topZoneLabel: "filterDropdown.topZoneLabel",
    noOptionsLabel: "filterDropdown.noOptionsLabel",
    operator: {
      is: "filterDropdown.operator.is",
      isnt: "filterDropdown.operator.isnt",
    },
  },
  profileSettingsForm: {
    languageItemTitle: "profileSettingsForm.languageItemTitle",
    languageItemDescription: "profileSettingsForm.languageItemDescription",
    themeItemTitle: "profileSettingsForm.themeItemTitle",
    themeItemDescription: "profileSettingsForm.themeItemDescription",
    timezoneItemTitle: "profileSettingsForm.timezoneItemTitle",
    timezoneItemDescription: "profileSettingsForm.timezoneItemDescription",
    dateFormatItemTitle: "profileSettingsForm.dateFormatItemTitle",
    dateFormatItemDescription: "profileSettingsForm.dateFormatItemDescription",
    checkboxLabel: "profileSettingsForm.checkboxLabel",
  },
  resetPassword: {
    commonTitle: "resetPassword.commonTitle",
    resetSuccessfully: "resetPassword.resetSuccessfully",
    setNewPasswordTitle: "resetPassword.setNewPasswordTitle",
    setNewPasswordSubTitle: "resetPassword.setNewPasswordSubTitle",
    newPasswordLabel: "resetPassword.newPasswordLabel",
    confirmationPasswordLabel: "resetPassword.confirmationPasswordLabel",
    inputPlaceholder: "resetPassword.inputPlaceholder",
    resetButton: "resetPassword.resetButton",
    emailInputPlaceholder: "resetPassword.emailInputPlaceholder",
    emailFormTitle: "resetPassword.emailFormTitle",
    emailFormSubTitle: "resetPassword.emailFormSubTitle",
    cancel: "resetPassword.cancel",
    confirmEmailForm: "resetPassword.confirmEmailForm",
    successEmailFormStart: "resetPassword.successEmailFormStart",
    successEmailFormEnd: "resetPassword.successEmailFormEnd",
  },
  tooltip: {
    time: {
      ongoingPeriod: "tooltip.time.ongoingPeriod",
      selectedPeriod: "tooltip.time.selectedPeriod",
    },
    metrics: {
      captureRate: "tooltip.metrics.captureRate",
      dwell: "tooltip.metrics.dwell",
      popularity: "tooltip.metrics.popularity",
      visitors: "tooltip.metrics.visitors",
      exits: "tooltip.metrics.exits",
      distribution: "tooltip.metrics.distribution",
    },
    filterAll: {
      count: "tooltip.filterAll",
      zero: "tooltip.filterAll.zero",
      one: "tooltip.filterAll.one",
      two: "tooltip.filterAll.two",
      other: "tooltip.filterAll.other",
    },
    filters: {
      count: "tooltip.filters",
      zero: "tooltip.filters.zero",
      one: "tooltip.filters.one",
      two: "tooltip.filters.two",
      other: "tooltip.filters.other",
    },
    groupBy: {
      count: "tooltip.groupBy",
      zero: "tooltip.groupBy.zero",
      one: "tooltip.groupBy.one",
      two: "tooltip.groupBy.two",
      other: "tooltip.groupBy.other",
    },
    preposition: {
      by: "tooltip.preposition.by",
      for: "tooltip.preposition.for",
      in: "tooltip.preposition.in",
      of: "tooltip.preposition.of",
      and: "tooltip.preposition.of",
    },
    option: {
      average: "tooltip.option.average",
      total: "tooltip.option.total",
    },
    inOut: "tooltip.inOut",
    gender: "tooltip.gender",
  },
  widgetGlobalFilters: {
    all: "widgetGlobalFilters.all",
    today: "widgetGlobalFilters.today",
    lastWeek: "widgetGlobalFilters.lastWeek",
    lastMonth: "widgetGlobalFilters.lastMonth",
    custom: "widgetGlobalFilters.custom",
    male: "widgetGlobalFilters.male",
    female: "widgetGlobalFilters.female",
    engaged: "widgetGlobalFilters.engaged",
    notEngaged: "widgetGlobalFilters.notEngaged",
  },
  metricLabel: {
    [MetricAggregations.MIN]: "metricLabel.minimum",
    [MetricAggregations.MAX]: "metricLabel.maximum",
    [MetricAggregations.AVERAGE]: "metricLabel.average",
    [MetricAggregations.SUM]: "metricLabel.total",
    [MetricAggregations.FIRST]: "metricLabel.first",
    [MetricAggregations.LAST]: "metricLabel.last",
    [MetricAggregations.MEDIAN]: "metricLabel.median",
    [MetricAggregations.COUNT]: "metricLabel.count",
    [MetricAggregations.EVOLUTION]: "metricLabel.evolution",
    [MetricAggregations.PERCENTAGE]: "metricLabel.percentage",
    [MetricAggregations.PERCENTILE_25]: "metricLabel.percentile25",
    [MetricAggregations.PERCENTILE_75]: "metricLabel.percentile75",
    [MetricAggregations.PERCENTILE_95]: "metricLabel.percentile95",
    [MetricAggregations.WEIGHTED_PERCENTAGE]: "metricLabel.weightedPercentage",
    highest: "metricLabel.highest",
    lowest: "metricLabel.lowest",
    limit: "metricLabel.limit",
    visitorInside: {
      count: "metricLabel.visitorInside",
      zero: "metricLabel.visitorInside.one",
      one: "metricLabel.visitorInside.one",
      other: "metricLabel.visitorInside.other",
    },
  },
  pieChartLabels: {
    all: "pieChartLabels.all",
    female: "pieChartLabels.female",
    male: "pieChartLabels.male",
    unknown: "pieChartLabels.unknown",
    child: "pieChartLabels.child",
    adult: "pieChartLabels.adult",
  },
  widget: {
    csvExport: "widget.csvExport",
    pngExport: "widget.pngExport",
    weather: {
      forecastDateFormat: "widget.weather.forecastDateFormat",
      todayDateFormat: "widget.weather.todayDateFormat",
      today: "widget.weather.today",
      forecast: "widget.weather.forecast",
      tooltip: "widget.weather.tooltip",
      title: "widget.weather.title",
    },
    subZones: {
      title: "widget.subZones.title",
      tooltip: "widget.subZones.tooltip",
    },
    alertTable: {
      title: "widget.alertTable.title",
      createNewAlertLabel: "widget.alertTable.createNewAlertLabel",
    },
    alertLogTable: {
      title: "widget.alertLogTable.title",
      header: {
        zone: "widget.alertLogTable.header.zone",
        type: "widget.alertLogTable.header.type",
        value: "widget.alertLogTable.header.value",
        threshold: "widget.alertLogTable.header.threshold",
        time: "widget.alertLogTable.header.time",
      },
      noAlertData: "widget.alertLogTable.noAlertData",
    },
  },
  reportGenerator: {
    title: "reportGenerator.title",
    subTitle: "reportGenerator.subTitle",
    bottomSubTitle: "reportGenerator.bottomSubTitle",
    send: "reportGenerator.send",
    min: "reportGenerator.min",
    hour: "reportGenerator.hour",
    hours: "reportGenerator.hours",
    daily: "reportGenerator.daily",
    weekly: "reportGenerator.weekly",
    monthly: "reportGenerator.monthly",
    from: "reportGenerator.from",
    to: "reportGenerator.to",
    installations: "reportGenerator.installations",
    granularity: "reportGenerator.granularity",
    email: "reportGenerator.email",
  },
  navigation: {
    backToLogin: "navigation.backToLogin",
    homePage: "navigation.homePage",
    exportPage: "navigation.exportPage",
  },
  onboarding: {
    phoneVerificationTitle: "onboarding.phoneVerificationTitle",
    phoneVerificationSubTitle: "onboarding.phoneVerificationSubTitle",
    formHasRequiredFields: "onboarding.formHasRequiredFields",
    prevStepButtonText: "onboarding.prevStepButtonText",
    nextStepButtonText: "onboarding.nextStepButtonText",
    completeOnboardingButtonText: "onboarding.completeOnboardingButtonText",
    optionalLabel: "onboarding.optionalLabel",
    steps: {
      [OnboardingStepKey.ORGANIZATION_SETTINGS]: {
        label: "onboarding.steps.organizationSettings.label",
        title: "onboarding.steps.organizationSettings.title",
        subTitle: "onboarding.steps.organizationSettings.subTitle",
      },
      [OnboardingStepKey.ABOUT]: {
        label: "onboarding.steps.about.label",
        title: "onboarding.steps.about.title",
        subTitle: "onboarding.steps.about.subTitle",
      },
      [OnboardingStepKey.PASSWORD]: {
        label: "onboarding.steps.password.label",
        title: "onboarding.steps.password.title",
        subTitle: "onboarding.steps.password.subTitle",
        passwordTitle: "onboarding.steps.password.passwordTitle",
        passwordSubTitle: "onboarding.steps.password.passwordSubTitle",
        confirmPasswordTitle: "onboarding.steps.password.confirmPasswordTitle",
        confirmPasswordSubTitle: "onboarding.steps.password.confirmPasswordSubTitle",
        passwordPlaceholder: "onboarding.steps.password.passwordPlaceholder",
      },
      [OnboardingStepKey.PROFILE_SETTINGS]: {
        label: "onboarding.steps.profileSettings.label",
        title: "onboarding.steps.profileSettings.title",
        subTitle: "onboarding.steps.profileSettings.subTitle",
      },
      [OnboardingStepKey.SECURITY]: {
        label: "onboarding.steps.security.label",
        title: "onboarding.steps.security.title",
        subTitle: "onboarding.steps.security.subTitle",
        extraFactorTitle: "onboarding.steps.security.extraFactorTitle",
        extraFactorSubTitle: "onboarding.steps.security.extraFactorSubTitle",
        activateExtraFactorLabel: "onboarding.steps.security.activateExtraFactorLabel",
        activateExtraFactorSubLabel: "onboarding.steps.security.activateExtraFactorSubLabel",
      },
    },
  },
  cookies: {
    title: "cookies.title",
    message: "cookies.message",
    essentialCookiesTitle: "cookies.essentialCookiesTitle",
    essentialCookiesDescription: "cookies.essentialCookiesDescription",
    alwaysActivated: "cookies.alwaysActivated",
    accept: "cookies.accept",
  },
  helpCenterQuestions: {
    generic: {
      question1: {
        title: "helpCenterQuestions.generic.question1.title",
        answer: "helpCenterQuestions.generic.question1.answer",
      },
      question2: {
        title: "helpCenterQuestions.generic.question2.title",
        answer: "helpCenterQuestions.generic.question2.answer",
      },
      question3: {
        title: "helpCenterQuestions.generic.question3.title",
        answer: "helpCenterQuestions.generic.question3.answer",
      },
      question4: {
        title: "helpCenterQuestions.generic.question4.title",
        answer: "helpCenterQuestions.generic.question4.answer",
      },
      question5: {
        title: "helpCenterQuestions.generic.question5.title",
        answer: "helpCenterQuestions.generic.question5.answer",
      },
      question6: {
        title: "helpCenterQuestions.generic.question6.title",
        answer: "helpCenterQuestions.generic.question6.answer",
      },
      question7: {
        title: "helpCenterQuestions.generic.question7.title",
        answer: "helpCenterQuestions.generic.question7.answer",
      },
      categoryName: "helpCenterQuestions.generic.categoryName",
    },
    product: {
      question1: {
        title: "helpCenterQuestions.product.question1.title",
        answer: "helpCenterQuestions.product.question1.answer",
      },
      question2: {
        title: "helpCenterQuestions.product.question2.title",
        answer: "helpCenterQuestions.product.question2.answer",
      },
      question3: {
        title: "helpCenterQuestions.product.question3.title",
        answer: "helpCenterQuestions.product.question3.answer",
      },
      question4: {
        title: "helpCenterQuestions.product.question4.title",
        answer: "helpCenterQuestions.product.question4.answer",
      },
      // We will need these translations later
      // question5: {
      //   title: "helpCenterQuestions.product.question5.title",
      //   answer: "helpCenterQuestions.product.question5.answer",
      // },
      // question6: {
      //   title: "helpCenterQuestions.product.question6.title",
      //   answer: "helpCenterQuestions.product.question6.answer",
      // },
      // question7: {
      //   title: "helpCenterQuestions.product.question7.title",
      //   answer: "helpCenterQuestions.product.question7.answer",
      // },
      question8: {
        title: "helpCenterQuestions.product.question8.title",
        answer: "helpCenterQuestions.product.question8.answer",
      },
      categoryName: "helpCenterQuestions.product.categoryName",
    },
  },
  sidebar: {
    sections: {
      customDashboards: "sidebar.sections.customDashboards",
    },
    profileOptions: {
      userProfile: "sidebar.profileOptions.userProfile",
    },
  },
  tutorial: {
    welcomeModalTitle: "tutorial.welcomeModalTitle",
    welcomeModalDescription: "tutorial.welcomeModalDescription",
    sidebar: {
      modalHeader: "tutorial.sidebar.modalHeader",
      title: "tutorial.sidebar.title",
      description: "tutorial.sidebar.description",
      clickInstruction: "tutorial.sidebar.clickInstruction",
    },
    installations: {
      modalHeader: "tutorial.installations.modalHeader",
      clickInstruction: "tutorial.installations.clickInstruction",

      title1: "tutorial.installations.title1",
      description1: "tutorial.installations.description1",

      title2: "tutorial.installations.title2",
      description2: "tutorial.installations.description2",
    },

    dashboardPage: {
      modalHeader: "tutorial.dashboardPage.modalHeader",
      title: "tutorial.dashboardPage.title",
      description: "tutorial.dashboardPage.description",
    },

    filteringSystem: {
      modalHeader: "tutorial.filteringSystem.modalHeader",

      title1: "tutorial.filteringSystem.title1",
      description1: "tutorial.filteringSystem.description1",

      title2: "tutorial.filteringSystem.title2",
      description2: "tutorial.filteringSystem.description2",
      title3: "tutorial.filteringSystem.title3",
      description3: "tutorial.filteringSystem.description3",
    },

    timespanFiltering: {
      modalHeader: "tutorial.timespanFiltering.modalHeader",

      title1: "tutorial.timespanFiltering.title1",
      description1: "tutorial.timespanFiltering.description1",
      clickInstruction: "tutorial.timespanFiltering.clickInstruction",

      title2: "tutorial.timespanFiltering.title2",
      description2: "tutorial.timespanFiltering.description2",

      title3: "tutorial.timespanFiltering.title3",
      description3: "tutorial.timespanFiltering.description3",
      fillInstruction: "tutorial.timespanFiltering.fillInstruction",

      description4: "tutorial.timespanFiltering.description4",

      description5: "tutorial.timespanFiltering.description5",

      title6: "tutorial.timespanFiltering.title6",
      description6: "tutorial.timespanFiltering.description6",
    },

    daysFiltering: {
      modalHeader: "tutorial.daysFiltering.modalHeader",

      title1: "tutorial.daysFiltering.title1",
      description1: "tutorial.daysFiltering.description1",
      clickInstruction: "tutorial.daysFiltering.clickInstruction",

      title2: "tutorial.daysFiltering.title2",

      title3: "tutorial.daysFiltering.title3",
      description3: "tutorial.daysFiltering.description3",
      setOperatorInstruction: "tutorial.daysFiltering.setOperatorInstruction",

      title4: "tutorial.daysFiltering.title4",
      description4: "tutorial.daysFiltering.description4",

      title5: "tutorial.daysFiltering.title5",
      description5: "tutorial.daysFiltering.description5",
    },

    zoneFiltering: {
      modalHeader: "tutorial.zoneFiltering.modalHeader",

      title1: "tutorial.zoneFiltering.title1",
      description1: "tutorial.zoneFiltering.description1",
      clickInstruction: "tutorial.zoneFiltering.clickInstruction",

      description2: "tutorial.zoneFiltering.description2",
      dropdownClickInstruction: "tutorial.zoneFiltering.dropdownClickInstruction",

      title3: "tutorial.zoneFiltering.title3",
      description3: "tutorial.zoneFiltering.description3",
      selectInstruction: "tutorial.zoneFiltering.selectInstruction",

      title4: "tutorial.zoneFiltering.title4",
      description4: "tutorial.zoneFiltering.description4",

      description5: "tutorial.zoneFiltering.description5",
    },

    widgets: {
      modalHeader: "tutorial.widgets.modalHeader",
      title1: "tutorial.widgets.title1",
      description1: "tutorial.widgets.description1",

      title2: "tutorial.widgets.title2",
      description2: "tutorial.widgets.description2",

      description3: "tutorial.widgets.description3",
      clickInstruction: "tutorial.widgets.clickInstruction",

      title4: "tutorial.widgets.title4",
      description4: "tutorial.widgets.description4",
    },

    quickSelectionTitle: "tutorial.quickSelectionTitle",
    applyInstruction: "tutorial.applyInstruction",
    plusButtonInstruction: "tutorial.plusButtonInstruction",
    firstZoneLabel: "tutorial.firstZoneLabel",
    selectedZoneLabel: "tutorial.selectedZoneLabel",
    nextButtonText: "tutorial.nextButtonText",
    prevButtonText: "tutorial.prevButtonText",
    skipButtonText: "tutorial.skipButtonText",
    zoneFirstFilterConditionLabel: "tutorial.zoneFirstFilterConditionLabel",
    kpiTooltipText: "tutorial.kpiTooltipText",
    modal: {
      welcome: {
        titlePart1: "tutorial.modal.welcome.titlePart1",
        titlePart2: "tutorial.modal.welcome.titlePart2",
        companyName: "tutorial.modal.welcome.companyName",
        content: "tutorial.modal.welcome.content",
        startButton: "tutorial.modal.welcome.startButton",
        skipButton: "tutorial.modal.welcome.skipButton",
      },
      confirmSkipping: {
        title: "tutorial.modal.confirmSkipping.title",
        content: "tutorial.modal.confirmSkipping.content",
        backToTutorialButton: "tutorial.modal.confirmSkipping.backToTutorialButton",
        skipButton: "tutorial.modal.confirmSkipping.skipButton",
      },
      complete: {
        title: "tutorial.modal.complete.title",
        subTitle: "tutorial.modal.complete.subTitle",
        content: "tutorial.modal.complete.content",
        advice1Part1: "tutorial.modal.complete.advice1Part1",
        advice1Part2: "tutorial.modal.complete.advice1Part2",
        advice1Link: "tutorial.modal.complete.advice1Link",
        advice2: "tutorial.modal.complete.advice2",
        advice2Link: "tutorial.modal.complete.advice2Link",
        advice3: "tutorial.modal.complete.advice3",
        advice3Link: "tutorial.modal.complete.advice3Link",
        endButton: "tutorial.modal.complete.endButton",
        goToHelpCenterButton: "tutorial.modal.complete.goToHelpCenterButton",
      },
    },
  },
  settings: {
    userManagement: {
      tabName: "settings.userManagement.tabName",
      table: {
        header: {
          users: "settings.userManagement.table.header.users",
          creation: "settings.userManagement.table.header.creation",
          twoFA: "settings.userManagement.table.header.twoFA",
        },
        twoFAActivated: "settings.userManagement.table.twoFAActivated",
        twoFANotActivated: "settings.userManagement.table.twoFANotActivated",
      },
      modal: {
        title: "settings.userManagement.modal.title",
      },
    },
    organization: {
      tabName: "settings.organization.tabName",
      placeholder: "settings.organization.placeholder",
    },
    alerts: {
      tabName: "settings.alerts.tabName",
      title: "settings.alerts.title",
      new: "settings.alerts.new",
      modal: {
        saveModalText: "settings.alerts.modal.saveModalText",
        deleteModalText: "settings.alerts.modal.deleteModalText",
        saveTitle: "settings.alerts.modal.saveTitle",
        deleteTitle: "settings.alerts.modal.deleteTitle",
      },
      noData: {
        title: "settings.alerts.noData.title",
        description: "settings.alerts.noData.description",
      },
    },
    alertDetails: {
      installation: {
        inputLabel: "settings.alertDetails.installation.inputLabel",
        inputPlaceholder: "settings.alertDetails.installation.inputPlaceholder",
      },
      event: {
        inputLabel: "settings.alertDetails.event.inputLabel",
        inputPlaceholder: "settings.alertDetails.event.inputPlaceholder",
      },
      notification: {
        type: {
          inputLabel: "settings.alertDetails.notification.type.inputLabel",
          options: {
            email: "settings.alertDetails.notification.type.options.email",
            push: "settings.alertDetails.notification.type.options.push",
            sms: "settings.alertDetails.notification.type.options.sms",
            job: "settings.alertDetails.notification.type.options.job",
          },
        },
        subject: {
          inputLabel: "settings.alertDetails.notification.subject.inputLabel",
          placeholder: "settings.alertDetails.notification.subject.placeholder",
        },
        message: {
          inputLabel: "settings.alertDetails.notification.message.inputLabel",
          placeholder: "settings.alertDetails.notification.message.placeholder",
        },
        recipients: {
          inputLabel: "settings.alertDetails.notification.recipients.inputLabel",
          placeholder: "settings.alertDetails.notification.recipients.placeholder",
        },
        addButtonText: "settings.alertDetails.notification.addButtonText",
      },
      condition: {
        type: {
          [ConditionType.CO2]: {
            name: "settings.alertDetails.condition.type.co2.name",
            description: "settings.alertDetails.condition.type.co2.description",
          },
          [ConditionType.FLUX]: {
            name: "settings.alertDetails.condition.type.flux.name",
            description: "settings.alertDetails.condition.type.flux.description",
          },
          [ConditionType.HUMIDITY]: {
            name: "settings.alertDetails.condition.type.humidity.name",
            description: "settings.alertDetails.condition.type.humidity.description",
          },
          [ConditionType.INTRUSION]: {
            name: "settings.alertDetails.condition.type.intrusion.name",
            description: "settings.alertDetails.condition.type.intrusion.description",
          },
          [ConditionType.LIMIT]: {
            name: "settings.alertDetails.condition.type.limit.name",
            description: "settings.alertDetails.condition.type.limit.description",
          },
          [ConditionType.STATUS]: {
            name: "settings.alertDetails.condition.type.status.name",
            description: "settings.alertDetails.condition.type.status.description",
          },
          [ConditionType.STOPNGO_LIMIT]: {
            name: "settings.alertDetails.condition.type.stopAndGo.name",
            description: "settings.alertDetails.condition.type.stopAndGo.description",
          },
          [ConditionType.TEMPERATURE]: {
            name: "settings.alertDetails.condition.type.temperature.name",
            description: "settings.alertDetails.condition.type.temperature.description",
          },
          [ConditionType.WAITING_TIME]: {
            name: "settings.alertDetails.condition.type.waitingTime.name",
            description: "settings.alertDetails.condition.type.waitingTime.description",
          },
          [ConditionType.REVERSE_DENSITY]: {
            name: "settings.alertDetails.condition.type.reverseDensity.name",
            description: "settings.alertDetails.condition.type.reverseDensity.description",
          },
          [ConditionType.COMFORT_SCALE]: {
            name: "settings.alertDetails.condition.type.comfortScale.name",
            description: "settings.alertDetails.condition.type.comfortScale.description",
          },
        },
        statusCode: {
          isOperationnal: "settings.alertDetails.condition.statusCode.isOperationnal",
          internetLost: "settings.alertDetails.condition.statusCode.internetLost",
          padsDisconnect: "settings.alertDetails.condition.statusCode.padsDisconnect",
        },
        inLabel: "settings.alertDetails.condition.inLabel",
        maxInputSubText: "settings.alertDetails.condition.maxInputSubText",
        minInputSubText: "settings.alertDetails.condition.minInputSubText",
        valueInputSubText: "settings.alertDetails.condition.valueInputSubText",
        selectPlaceholder: "settings.alertDetails.condition.selectPlaceholder",
        zoneSubText: "settings.alertDetails.condition.zoneSubText",
        valueInputPlaceholder: "settings.alertDetails.condition.valueInputPlaceholder",
        directionInputSubText: "settings.alertDetails.condition.directionInputSubText",
        codes: "settings.alertDetails.condition.codes",
        enterDevicePlaceholder: "settings.alertDetails.condition.enterDevicePlaceholder",
        codesInputPlaceholder: "settings.alertDetails.condition.codesInputPlaceholder",
        placeholder: "settings.alertDetails.condition.placeholder",
        header: "settings.alertDetails.condition.header",
        clearAll: "settings.alertDetails.condition.clearAll",
        inDirectionOptionLabel: "settings.alertDetails.condition.inDirectionOptionLabel",
        outDirectionOptionLabel: "settings.alertDetails.condition.outDirectionOptionLabel",
        inDirectionLabel: "settings.alertDetails.condition.inDirectionLabel",
        outDirectionLabel: "settings.alertDetails.condition.outDirectionLabel",
      },
    },
    newAlert: {
      name: {
        title: "settings.newAlert.name.title",
        subTitle: "settings.newAlert.name.subTitle",
        inputTitle: "settings.newAlert.name.inputTitle",
        inputTitlePlaceholder: "settings.newAlert.name.inputTitlePlaceholder",
      },
      installationAndEvent: {
        title: "settings.newAlert.installationAndEvent.title",
        subTitle: "settings.newAlert.installationAndEvent.subTitle",
        tooltip: "settings.newAlert.installationAndEvent.tooltip",
      },
      condition: {
        title: "settings.newAlert.condition.title",
        subTitle: "settings.newAlert.condition.subTitle",
        tooltip: "settings.newAlert.condition.tooltip",
      },
      notification: {
        title: "settings.newAlert.notification.title",
        subTitle: "settings.newAlert.notification.subTitle",
        tooltip: "settings.newAlert.notification.tooltip",
      },
    },
    updateAlert: {
      emptyBreadcrumbPlaceholder: "settings.updateAlert.emptyBreadCrumbPlaceholder",
    },
    alertsList: {
      alertLabel: "settings.alertsList.alertLabel",
    },
  },
  exportData: {
    exitModalTitle: "exportData.exitModalTitle",
    exitModalDescription: "exportData.exitModalDescription",
    actions: {
      save: "exportData.actions.save",
    },
    navigation: {
      caption: "exportData.navigation.caption",
    },
    dataSelection: {
      title: "exportData.dataSelection.title",
      tooltipContent: "exportData.dataSelection.tooltipContent",
    },
    timespanSelection: {
      title: "exportData.timespanSelection.title",
      subtitle: "exportData.timespanSelection.subtitle",
    },
    granularity: {
      title: "exportData.granularity.title",
      subtitle: "exportData.granularity.subtitle",
      min: "exportData.granularity.min",
      hour: "exportData.granularity.hour",
      day: "exportData.granularity.day",
      week: "exportData.granularity.week",
      month: "exportData.granularity.month",
    },
    recipients: {
      title: "exportData.recipients.title",
      subtitle: "exportData.recipients.subtitle",
    },
    options: {
      title: "exportData.options.title",
      subzonesOption: "exportData.options.subzonesOption",
      passagesOption: "exportData.options.passagesOption",
    },
    dataType: {
      label: "exportData.dataType.label",
      placeholder: "exportData.dataType.placeholder",
    },
    zone: {
      label: "exportData.zone.label",
      placeholder: "exportData.zone.placeholder",
    },
  },
  userProfile: {
    breadcrumb: "userProfile.breadcrumb",
    general: {
      otpContactModal: {
        title: "userProfile.general.otpContactModal.title",
        description: "userProfile.general.otpContactModal.description",
        approveButtonText: "userProfile.general.otpContactModal.approveButtonText",
      },
      tabName: "userProfile.general.tabName",
      fullName: {
        title: "userProfile.general.fullName.title",
        subTitle: "userProfile.general.fullName.subTitle",
      },
      firstName: {
        placeholder: "userProfile.general.firstName.placeholder",
      },
      lastName: {
        placeholder: "userProfile.general.lastName.placeholder",
      },
      phoneNumber: {
        title: "userProfile.general.phoneNumber.title",
        subText: "userProfile.general.phoneNumber.subText",
      },
    },
    preferences: {
      tabName: "userProfile.preferences.tabName",
      title: "userProfile.preferences.title",
    },
    security: {
      tabName: "userProfile.security.tabName",
      password: {
        title: "userProfile.security.password.title",
        subTitle: "userProfile.security.password.subTitle",
        newPassword: {
          label: "userProfile.security.password.newPassword.label",
          placeholder: "userProfile.security.password.newPassword.placeholder",
        },
        newPasswordConfirmation: {
          label: "userProfile.security.password.newPasswordConfirmation.label",
          placeholder: "userProfile.security.password.newPasswordConfirmation.placeholder",
        },
        oldPassword: {
          label: "userProfile.security.password.oldPassword.label",
          placeholder: "userProfile.security.password.oldPassword.placeholder",
        },
      },
      twoFA: {
        title: "userProfile.security.twoFA.title",
        subTitle: "userProfile.security.twoFA.subTitle",
        label: "userProfile.security.twoFA.label",
        contactMethod: {
          label: "userProfile.security.twoFA.contactMethod.label",
          placeholder: "userProfile.security.twoFA.contactMethod.placeholder",
        },
      },
    },
    securityLabel: {
      verified: "userProfile.securityLabel.verified",
      twoFA: "userProfile.securityLabel.twoFA",
      notVerified: "userProfile.securityLabel.notVerified",
    },
  },
};
