/* eslint-disable @typescript-eslint/no-unused-vars */
// noinspection JSUnusedGlobalSymbols

import { type TrackingAction, TrackingActionCategory, TrackingUserActionTypes, type User } from "@technis/shared";

import { debounce } from "lodash";

import { type AnyObject } from "yup/lib/types";

import { GATracking } from "@namespaces/googleTracking";
import { SegmentTracking } from "@namespaces/segmentTracking";
import { TechnisTracking } from "@namespaces/technisTracking";

import { type UserData } from "@services/userService";

const { RETAIL } = TrackingActionCategory;

const TRACKING_DEBOUNCE_TIME = 800;

export const TOPICS = {
  AUTH: "Auth",
  HOME: "Home",
  HOME2: "Home2",
  ONBOARDING: "Onboarding",
  DASHBOARD: "Dashboard",
  CUSTOM_DASHBOARD: "Custom Dashboard",
  TUTORIAL: "Tutorial",
  NOT_FOUND: "Not found",
  EXPORT_DATA: "Export Data",
  HELP: "Help",
  ERROR: "Error",
  SETTINGS: "Settings",
  USER_PROFILE: "User Profile",
};

export const PAGES = {
  LOGIN: "Login",
  RESET_PASSWORD_CONFIRM: "Confirm",
  HOME: "Home",
  HOME2: "Home2",
  ONBOARDING_INVITATION: "Invitation Accept",
  ONBOARDING_ORGANIZATION_SETTINGS: "Organization Settings",
  ONBOARDING_ABOUT: "About",
  ONBOARDING_PASSWORD: "Password",
  ONBOARDING_PROFILE_SETTINGS: "Profile Settings",
  ONBOARDING_SECURITY: "Security",
  COUNTING_INSTALLATION_LIST: "Counting List",
  COUNTING_INSTALLATION_DETAIL: "Counting Detail",
  AIR_QUALITY_INSTALLATION_LIST: "Air Quality List",
  AIR_QUALITY_INSTALLATION_DETAIL: "Air Quality Detail",
  CUSTOM_DASHBOARD_PAGE: (pageName: string): string => pageName,
  TUTORIAL_INSTALLATIONS: "Tutorial Installation",
  TUTORIAL_INSTALLATIONS_DETAILS: "Tutorial Installation Details",
  HELP_CENTER: "Help Center",
  NOT_FOUND: "Not found",
  SETTINGS: "Settings",
  EXPORT_DATA: "Export Data",
  USER_PROFILE: "User Profile",
};

export const TRACKING_EVENTS = {
  USER_ATTEMPTED_LOGIN: "User Attempted Login",
  USER_LOGGED_IN: "User Logged In",
  USER_LOGGED_OUT: "User Logged Out",
  INSTALLATION_LIST_SEARCH_FOCUSED: "Installation List Search Focused",
  INSTALLATION_LIST_SEARCH_SUBMITTED: "Installation List Search Submitted",
  INSTALLATION_LIST_SEARCH_CLICKED: "Installation List Search Clicked",
  INSTALLATION_LIST_SEARCH_RESULT_DISPLAYED: "Installation List Search Result Displayed",
  HOME_HELP_CENTER_CLICKED: "Home Help Center Clicked",
  HOME_AIR_QUALITY_INSTALLATION_LIST_CLICKED: "Home Air Quality Installation List Clicked",
  HOME_COUNTING_INSTALLATION_LIST_CLICKED: "Home Counting Installation List Clicked",
  SIDEBAR_HOME_CLICKED: "Sidebar Home Link Clicked",
  SIDEBAR_HELP_CENTER_CLICKED: "Sidebar Help Center Link Clicked",
  SIDEBAR_COUNTING_CLICKED: "Sidebar Counting Link Clicked",
  SIDEBAR_AIR_QUALITY_CLICKED: "Sidebar Air Quality Link Clicked",
  TIMESPAN_FILTER_OPENED: "Timespan Filter Opened",
  TIMESPAN_FILTER_CLOSED: "Timespan Filter Closed",
  TIMESPAN_FILTER_APPLIED: "Timespan Filter Applied",
  TIMESPAN_FILTER_QUICK_SELECTION_CLICKED: "Timespan Filter Quick Selection Clicked",
  TIMESPAN_FILTER_QUICK_SELECTION_SELECTED: "Timespan Filter Quick Selection Selected",
  DAY_FILTER_OPENED: "Day Filter Opened",
  DAY_FILTER_CLOSED: "Day Filter Closed",
  DAY_FILTER_APPLIED: "Day Filter Applied",
  ZONE_FILTER_OPENED: "Zone Filter Opened",
  ZONE_FILTER_CLOSED: "Zone Filter Closed",
  ZONE_FILTER_APPLIED: "Zone Filter Applied",
  EVENT_FILTER_OPENED: "Event Filter Opened",
  EVENT_FILTER_CLOSED: "Event Filter Closed",
  EVENT_FILTER_APPLIED: "Event Filter Applied",
  QUESTION_OPENED: "Question Opened",
  TUTORIAL_STEP_CHANGED: "Tutorial Step Changed",
  ALERT_CREATED: "Alert Created",
  ALERT_DELETED: "Alert Deleted",
  ALERT_NEW_CLICKED: "Alert New Button Clicked",
  EXPORT_CLICKED: 'Export "Send" button Clicked',
  EXPORT_INSTALLATION: "Installation Export Data button Clicked",
  SIDEBAR_EXPORT: "Sidebar Export Data button Clicked",
  USER_PROFILE_2FA_TOGGLED: "User profile 2FA toggle Clicked",
  USER_PROFILE_LANGUAGE_CHANGED: "User profile Language Edited",
  USER_PROFILE_OTP_PHONE_VERIFIED: "User profile OTP Phone Verified",
  USER_PROFILE_GENERAL_SAVED: "User profile General Saved",
  USER_PROFILE_PASSWORD_SAVED: "User profile Password Saved",
};

export namespace Tracking {
  const trackAction =
    (type: TrackingUserActionTypes) =>
    <T extends AnyObject & Omit<TrackingAction, "id" | "projectId" | "receivedAt" | "sessionId" | "category">>(payload: T): void => {
      const { name, externalId, type } = payload;
      TechnisTracking.saveAction({ category: RETAIL, name, externalId, type });
      GATracking.saveAction({ category: RETAIL, name, externalId, type });
      SegmentTracking.saveAction(payload);
    };

  export const trackClick = trackAction(TrackingUserActionTypes.CLICK);
  export const trackToggle = trackAction(TrackingUserActionTypes.TOGGLE);
  export const trackTyping = debounce(trackAction(TrackingUserActionTypes.TYPING), TRACKING_DEBOUNCE_TIME);
  export const trackCheckbox = trackAction(TrackingUserActionTypes.CHECKBOX);
  export const trackFocus = trackAction(TrackingUserActionTypes.FOCUS);
  export const trackSelect = trackAction(TrackingUserActionTypes.SELECT);

  export const setUser = (user: UserData, adminUserId?: User["id"]): void => {
    const userId = adminUserId || user.id;
    const isViewAsUser = !!adminUserId;

    TechnisTracking.setUser(userId);
    GATracking.setUser(userId, { ...user, isViewAsUser });
    SegmentTracking.setUser(userId, { ...user, isViewAsUser });
  };
}
